import { slug_decorators } from "./slug.decorators";

/**
 * Classe que define as permissões estáticas de acesso aos recursos do sistema
 */
export const permissoes_decorators = {

    "CURADORIA":{
        "VISUALIZAR": slug_decorators.CURADORIA.VISUALIZAR,
        "MODELO": {
            "VISUALIZAR": slug_decorators.CURADORIA.MODELO.ACESSAR,
            "ACESSAR": slug_decorators.CURADORIA.MODELO.ACESSAR
        },
        "PENDENCIA": {
            "VISUALIZAR": slug_decorators.CURADORIA.MODELO.ACESSAR,
            "ACESSAR": slug_decorators.CURADORIA.PENDENCIA.ACESSAR,
            "CONSULTAR": slug_decorators.CURADORIA.PENDENCIA.CONSULTAR
        }
    },
    "RECOMENDACAO": [
        slug_decorators.CURADORIA.PENDENCIA.CONSULTAR,
        slug_decorators.CONFIABILIDADE.ERP.CONSULTAR,
        slug_decorators.CONFIABILIDADE.INTERPRETADOR.CONSULTAR
    ]

};
