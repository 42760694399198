import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { PermissionGuardService } from "./guards/permission-guard.service";
import { LoadDataGuardService } from "./guards/load-data-guard.service";

const appRoutes: Routes = [
  {

    path: "",
    loadChildren: () =>
      import("./pages/login/login.module").then((m) => m.LoginModule),
  },
  {
    path: "home",
    canActivate: [LoadDataGuardService, PermissionGuardService],
    loadChildren: () =>
      import("./pages/home/home.module").then((m) => m.HomeModule)
  },
  {
    path: "default",
    canActivate: [LoadDataGuardService],
    loadChildren: () =>
      import("./pages/default-page/default-page.module").then((m) => m.DefaultPageModule)
  },
  {
    path: "sintese-processo",
    canActivate: [LoadDataGuardService, PermissionGuardService],
    loadChildren: () =>
      import("./pages/processo/sintese-processo/sintese-processo.module").then(
        (m) => m.SinteseProcessoModule
      ),
  },
  {
    path: "manifesto",
    canActivate: [LoadDataGuardService, PermissionGuardService],
    loadChildren: () =>
      import("./pages/monitor-manifesto/monitor-manifesto.module").then(
        (m) => m.MonitorManifestoModule
      ),
  },
  // {
  //   path: "dashboard",
  //   component: DashboardComponent,
  //   canActivate: [LoadDataGuardService],
  // },
  {
    path: "logout",
    canActivate: [],
    loadChildren: () =>
      import("./pages/logout/logout.module").then((m) => m.LogoutModule),
  },
  {
    path: "erro",
    loadChildren: () =>
      import("./pages/erro/erro.module").then((m) => m.ErroModule),
  },
  {
    path: "logs",
    canActivate: [LoadDataGuardService, PermissionGuardService],
    loadChildren: () =>
      import("./pages/relatorios/logs/logs.module").then((m) => m.LogsModule),
  },
  {
    path: "relatorios/aprovacao",
    canActivate: [LoadDataGuardService, PermissionGuardService],
    loadChildren: () =>
      import(
        "./pages/relatorios/logsemailaprovacao/logsemailaprovacao.module"
      ).then((m) => m.LogsemailaprovacaoModule),
  },
  {
    path: "admin/grupos",
    canActivate: [LoadDataGuardService, PermissionGuardService],
    loadChildren: () =>
      import("./pages/admin/grupofilial/grupofilial.module").then(
        (m) => m.GrupoFilialModule
      ),
  },
  {
    path: "admin/usuarios",
    canActivate: [LoadDataGuardService, PermissionGuardService],
    loadChildren: () =>
      import("./pages/admin/usuario/usuario.module").then(
        (m) => m.UsuarioModule
      ),
  },
  {
    path: "relatorios/anexos",
    canActivate: [LoadDataGuardService, PermissionGuardService],
    loadChildren: () =>
      import(
        "./pages/relatorios/logprocessoanexo/logprocessoanexo.module"
      ).then((m) => m.LogprocessoanexoModule),
  },
  {
    path: "admin/configuracoes",
    canActivate: [LoadDataGuardService, PermissionGuardService],
    loadChildren: () =>
      import("./pages/admin/configuracao/config.module").then(
        (m) => m.ConfigModule
      ),
  },
  {
    path: "admin/validacoes",
    canActivate: [LoadDataGuardService, PermissionGuardService],
    loadChildren: () =>
      import("./pages/admin/validacao/validacao.module").then(
        (m) => m.ValidacaoModule
      ),
  },
  {
    path: "admin/unidade-medida",
    canActivate: [LoadDataGuardService, PermissionGuardService],
    loadChildren: () =>
      import(
        "./pages/admin/cadastros/unidade-medida/unidade-medida.module"
      ).then((m) => m.UnidadeMedidaModule),
  },
  {
    path: "relatorios/volumetria-processos",
    canActivate: [LoadDataGuardService],
    loadChildren: () =>
      import("./pages/relatorios/volumetria/volumetria.module").then(
        (m) => m.VolumetriaModule
      ),
  },
  {
    path: "relatorios/auxiliar",
    canActivate: [LoadDataGuardService, PermissionGuardService],
    loadChildren: () =>
      import("./pages/relatorios/auxiliar/auxiliar.module").then(
        (m) => m.AuxiliarModule
      ),
  },
  {
    path: "relatorios/notas-canceladas",
    canActivate: [LoadDataGuardService, PermissionGuardService],
    loadChildren: () =>
      import("./pages/relatorios/notas-canceladas/notas-canceladas.module").then(
        (m) => m.NotasCanceladasModule
      ),
  },
  {
    path: 'relatorios/avancados',
    canActivate: [LoadDataGuardService, PermissionGuardService],
    loadChildren: () =>
      import('./pages/relatorios/avancados/avancado.module').then(
        (m) => m.AvancadoModule
      ),
  },
  {
    path: "admin/reclassificar-requisitante",
    canActivate: [LoadDataGuardService, PermissionGuardService],
    loadChildren: () =>
      import(
        "./pages/admin/reclassificar-aprovador/reclassificar-aprovador.module"
      ).then((m) => m.ReclassificarAprovadorModule),
  },
  {
    path: "admin/fornecedores-confiaveis",
    canActivate: [LoadDataGuardService, PermissionGuardService],
    loadChildren: () =>
      import(
        "./pages/admin/fornecedores-confiaveis/fornecedores-confiaveis.module"
      ).then((m) => m.FornecedoresConfiaveisModule),
  },
  {
    path: "admin/nfe-cnpj",
    canActivate: [LoadDataGuardService, PermissionGuardService],
    loadChildren: () =>
      import(
        "./pages/admin/nfe-cnpj/nfe-cnpj.module"
      ).then((m) => m.NfeCnpjModule),
  },
  {
    path: "admin/cte-cnpj",
    canActivate: [LoadDataGuardService, PermissionGuardService],
    loadChildren: () =>
      import(
        "./pages/admin/cte-cnpj/cte-cnpj.module"
      ).then((m) => m.CteCnpjModule),
  },
  {
    path: "admin/tomador",
    canActivate: [LoadDataGuardService, PermissionGuardService],
    loadChildren: () =>
      import(
        "./pages/admin/tomador-destinatario/tomador-destinatario.module"
      ).then((m) => m.TomadorDestinatarioModule),
  },
  {
    path: "admin/idprocesso",
    canActivate: [LoadDataGuardService, PermissionGuardService],
    loadChildren: () =>
      import(
        "./pages/admin/identificador_processo/identificador-processo.module"
      ).then((m) => m.IdentificadorProcessoModule),
  },
  {
    path: "admin/fluxo-tolerancia",
    canActivate: [LoadDataGuardService, PermissionGuardService],
    loadChildren: () =>
      import(
        "./pages/admin/tolerancia-fluxo/tolerancia-fluxo.module"
      ).then((m) => m.FluxoToleranciaModule),
  },
  {
    path: "admin/perfis",
    canActivate: [LoadDataGuardService, PermissionGuardService],
    loadChildren: () =>
      import("./pages/admin/perfil/perfil.module").then(
        (m) => m.PefilModule
      ),
  },
  // {
  //   path: "processo/portaria",
  //   canActivate: [LoadDataGuardService, PermissionGuardService],
  //   loadChildren: () =>
  //     import("./pages/portaria/portaria.module").then((m) => m.PortariaModule),
  // },

  // {
  //   path: "processo/doca",
  //   canActivate: [LoadDataGuardService, PermissionGuardService],
  //   loadChildren: () =>
  //     import("./pages/doca/doca.module").then((m) => m.DocaModule),
  // },
  {
    path: "recebimento",
    canActivate: [LoadDataGuardService, PermissionGuardService],
    loadChildren: () =>
      import("./pages/recebimento/monitor/monitor.module").then((m) => m.MonitorModule)
  },
  {
    path: "recebimento/sintese-recebimento",
    canActivate: [LoadDataGuardService, PermissionGuardService],
    runGuardsAndResolvers: 'always',
    loadChildren: () =>
      import("./pages/recebimento/sintese-recebimento/sintese-recebimento.module").then((m) => m.SinteseRecebimentoModule)
  },
  {
    path: "admin/imposto",
    canActivate: [LoadDataGuardService, PermissionGuardService],
    loadChildren: () =>
      import(
        "./pages/admin/imposto/imposto.module"
      ).then((m) => m.ImpostoModule),
  },
  {
    path: "admin/certificados",
    canActivate: [LoadDataGuardService, PermissionGuardService],
    loadChildren: () =>
      import(
        "./pages/admin/certificados/certificados.module"
      ).then((m) => m.CertificadosModule),
  },
  {
    path: "admin/config-cnpj",
    canActivate: [LoadDataGuardService, PermissionGuardService],
    loadChildren: () =>
      import(
        "./pages/admin/configuracao-cnpj/configuracao-cnpj.module"
      ).then((m) => m.ConfiguracaoCnpjModule),
  },
  {
    path: "curadoria/treinar",
    // canActivate: [LoadDataGuardService, PermissionGuardService],
    loadChildren: () =>
      import(
        "./pages/curadoria/modelo/modelo-treinar/modelo-treinar.module"
      ).then((m) => m.ModeloTreinarModule),
  },

  {
    path: "curadoria/consultar",
    canActivate: [LoadDataGuardService, PermissionGuardService],
    loadChildren: () =>
      import(
        "./pages/curadoria/modelo/modelo-consultar/modelo-consultar.module"
      ).then((m) => m.ModeloConsultarModule),
  },

  {
    path: "curadoria/status",
    // canActivate: [LoadDataGuardService, PermissionGuardService],
    loadChildren: () =>
      import(
        "./pages/curadoria/modelo/modelo-status/modelo-status.module"
      ).then((m) => m.ModeloStatusModule),
  },

  {
    path: "curadoria/interpretar",
    // canActivate: [LoadDataGuardService, PermissionGuardService],
    loadChildren: () =>
      import(
        "./pages/curadoria/modelo/modelo-interpretar/modelo-interpretar.module"
      ).then((m) => m.ModeloInterpretarModule),
  },

  {
    path: "curadoria/pendencia",
    canActivate: [LoadDataGuardService, PermissionGuardService],
    loadChildren: () =>
      import(
        "./pages/relatorios/pendencia-curadoria/pendencia-curadoria.module"
      ).then((m) => m.PendenciaCuradoriaModule),
  },

  {
    path: "recomendacao",
    canActivate: [LoadDataGuardService, PermissionGuardService],
    loadChildren: () =>
      import(
        "./pages/recomendacao/recomendacao.module"
      ).then((m) => m.RecomendacaoModule),
  },

  // ,{
  //   path: 'curadoria/comparar',
  // canActivate: [LoadDataGuardService, PermissionGuardService],
  //   component: ModeloConsultarComponent, canActivate: [MsalGuard]
  // }

  {
    path: "crudsidebar",
    // canActivate: [LoadDataGuardService, PermissionGuardService],
    loadChildren: () =>
      import("./_dev/components/crud-sidebar-ini/crud-sidebar-ini.module").then(
        (m) => m.CrudSidebarIniModule
      ),
  },

  // Esta rota precisa ser a última, visto que é uma rota de NotFound
  {
    path: "**",
    redirectTo: "/",
  },
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes, { useHash: true, onSameUrlNavigation: 'reload' })],
  exports: [RouterModule],
})
export class AppRoutingModule { }
