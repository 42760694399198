import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { IMessageServiceFacade } from '../facade/message.interface';

@Injectable({
  providedIn: 'root'
})
export class MessageToastrService implements IMessageServiceFacade {

  protected _toastr: ToastrService;
  protected _config = {}


  constructor( _toastrSrv: ToastrService ) { 

      this._toastr = _toastrSrv;

      this._config = this._toastr.toastrConfig;


  }
  showSucessMultiMessage(msgID: string, message: any) {
    throw new Error("Method not implemented.");
  }
  showInfoMultiMessage(msgID: string, message: any) {
    throw new Error("Method not implemented.");
  }
  showWarnMultiMessage(msgID: string, message: any) {
    throw new Error("Method not implemented.");
  }
  showErrorMultiMessage(msgID: string, message: any) {
    throw new Error("Method not implemented.");
  }
  showSucessMessage(alerta: any) {
    throw new Error("Method not implemented.");
  }
  showInfoMessage(alerta: any) {
    throw new Error("Method not implemented.");
  }
  showWarnMessage(alerta: any) {
    throw new Error("Method not implemented.");
  }
  showErrorMessage(alerta: any) {
    throw new Error("Method not implemented.");
  }

  showMessage(alerta: {}) {
    throw new Error("Method not implemented.");
  }

  showSucessMessageConfig(alerta:any, config:{}){
    if(alerta['msg']){
      this._toastr.success(alerta['msg'], alerta['tit'], config);
    }else{
      this._toastr.success(alerta,'', config);
    }
  }

  showInfoMessageConfig(alerta:any, config:{}){
    if(alerta['msg']){
      this._toastr.info(alerta['msg'], alerta['tit'], config);
    }else{
      this._toastr.info(alerta,'', config);
    }
  }

  showWarnMessageConfig(alerta:any, config:{}){
    if(alerta['msg']){
      this._toastr.warning(alerta['msg'], alerta['tit'], config);
    }else{
      this._toastr.warning(alerta,'', config);
    }
   // this.logWarnMessage(alerta['msg']);
  }

  showErrorMessageConfig(alerta:any, config:{}){

    if(alerta['msg']){
      this._toastr.error(alerta['msg'], alerta['tit'], config);
    }else{
      this._toastr.error(alerta,'', config);
    }
    //this.logErrorMessage(alerta);
  }

}
