import { Component, OnInit } from "@angular/core";
import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from "@angular/platform-browser";
import { DataUtils } from "src/app/commons/data.utils";
import { Notificacao } from "@models/notificacao";
import { RecomendacaoActionsService } from "@services/actions/recomendacao-actions.service";
import { Recomendacao } from "@models/recomendacao";
import { EnvironmentDecorator } from "@decorators/environment.decorator";
import { IMessageServiceFacade } from "@services/facade/message.interface";
import { MessageService } from "@services/messages/message.service";
import { constants_decorators } from "@decorators/constants.decorators";
import * as moment from "moment";
import { Router } from "@angular/router";
import { PermissionGuardService } from "src/app/guards/permission-guard.service";
import { EnvService } from "@services/env.service";

@Component({
  selector: "app-notification-menu",
  templateUrl: "./notification-menu.component.html",
  styleUrls: ["./notification-menu.component.scss"],
})
export class NotificationMenuComponent implements OnInit {
  protected _recomensacaoActServ: RecomendacaoActionsService;

  // numeroNotificacoesNovas = 0;
  showNotification: boolean;
  notificacoes: Array<Notificacao> = [];

  public _dataUtils: DataUtils;

  @EnvironmentDecorator()
  _EnvironmentDecorator;
  envDecorators = [];

  private _constantDecorators = constants_decorators;

  public _msgSrv: IMessageServiceFacade;
  public _router: Router;

  _permissionSrv: PermissionGuardService;

  notificationCheckInterval: any;
  notificationCheckTempo: number;

  constructor(
    _router: Router,
    _iconRegistry: MatIconRegistry,
    _messageSrv: MessageService,
    _actionSrv: RecomendacaoActionsService,
    _sanitizer: DomSanitizer,
    _permissionGuardSrv: PermissionGuardService
  ) {
    this._dataUtils = new DataUtils();
    this._msgSrv = _messageSrv;
    this._router = _router;

    this._recomensacaoActServ = _actionSrv;
    this.envDecorators = this._EnvironmentDecorator[
      this._constantDecorators.CONTEXTOS.RECOMENDACAO.toLowerCase()
    ];

    this._permissionSrv = _permissionGuardSrv;

    _iconRegistry.addSvgIcon(
      "notifications",
      _sanitizer.bypassSecurityTrustResourceUrl(EnvService.assetsUrl("assets/img/notifications.svg"))
    );
    _iconRegistry.addSvgIcon(
      "check",
      _sanitizer.bypassSecurityTrustResourceUrl(EnvService.assetsUrl("assets/img/check.svg"))
    );
    _iconRegistry.addSvgIcon(
      "confiabilidade",
      _sanitizer.bypassSecurityTrustResourceUrl(
        EnvService.assetsUrl("assets/img/thumb_up_alt-24px.svg")
      )
    );
    _iconRegistry.addSvgIcon(
      "modelo-ia",
      _sanitizer.bypassSecurityTrustResourceUrl(EnvService.assetsUrl("assets/img/modelo-ia.svg"))
    );
    _iconRegistry.addSvgIcon(
      "excelencia",
      _sanitizer.bypassSecurityTrustResourceUrl(EnvService.assetsUrl("assets/img/military_tech.svg"))
    );
    _iconRegistry.addSvgIcon(
      "novo",
      _sanitizer.bypassSecurityTrustResourceUrl(EnvService.assetsUrl("assets/img/new_releases.svg"))
    );

  }

  ngOnInit(): void {
    this.recuperarNotificacoes();

    this.initCheckNotificacoesTimertask();

  }

  initCheckNotificacoesTimertask(){
    this.notificationCheckTempo = this._constantDecorators.TEMPO_MS.UM_MINUTO * 5;
    this.notificationCheckInterval = setInterval(async () => {
      await this.recuperarNotificacoesPorIntervalo();
    }, this.notificationCheckTempo);
  }

  async recuperarNotificacoesPorIntervalo() {
    try {
      if(this.notificationCheckInterval){
        clearInterval(this.notificationCheckInterval);
        this.initCheckNotificacoesTimertask();
        await this.recuperarNotificacoes();
      }
    } catch (error) {
      console.log("Erro ao executar tarefa temporal para atualizar notificações. Mensagem do sisteme: "+error)
       //Intervalo ainda não foi criado.
    }
 }

  async recuperarNotificacoes() {
    try {

      let recomendacoesRecuperadas: Array<Recomendacao>;
      let notificacoesAgrupadas: Array<Notificacao>;
      notificacoesAgrupadas = new Array();
      this.notificacoes = new Array();
      // this.numeroNotificacoesNovas = 0;

      recomendacoesRecuperadas = await this._recomensacaoActServ.recuperarNovas();
      // this.numeroNotificacoesNovas = recomendacoesRecuperadas.length;

      let now = new Date();
      for (let cont = 0; cont < recomendacoesRecuperadas.length; cont++) {

        const recomendacao = recomendacoesRecuperadas[cont];
        const slug = recomendacao.slug;

        if (this._permissionSrv._filterConsult(slug)) {

          // this.numeroNotificacoesNovas++;

          let tipo = recomendacao.tipo;
          let titulo = recomendacao.titulo;
          const chaveNot = tipo;

          // let notificacao = notificacoesAgrupadas[chaveNot] || {};
          let notificacao = new Notificacao();

          let dtNot = new Date(recomendacao.created_at);
          //dtNot.setHours(dtNot.getHours() - 3);
          let tempoDecNot1 = this._dataUtils.formatTimeElapsed(dtNot, now);
          let qtde = notificacao["quantidade"] || 0;
          let qtdeNovas = qtde;
          notificacao = {
            titulo: recomendacao.titulo,
            texto: recomendacao.mensagem,
            novo: recomendacao.view_at == null,
            criticidade: recomendacao.criticidade,
            quantidade: ++qtde,
            quantidadeNovas: ++qtdeNovas,
            data: now,
            tempoDecorrido: tempoDecNot1,
            icone: this.getIcone(tipo),
            cor: "--color-attention",
          };

          this.notificacoes.push(notificacao);
        }
      }
      //);

      // let dtNot2 = new Date();
      // dtNot2.setMinutes(now.getMinutes() - 10)
      // let tempoDecNot2 = this._dataUtils.formatTimeElapsed(dtNot2, now);
      // let not2 = {
      //   titulo: "Recomendação de Confiabilidade",
      //   texto: "Falha na conexão com ERP",
      //   novo: true,
      //   criticidade: 7,
      //   quantidade: 1,
      //   quantidadeNovas: 1,
      //   data: dtNot2,
      //   tempoDecorrido: tempoDecNot2,
      //   icone: "confiabilidade",
      //   cor: "--color-accent",
      // };

      // this.numeroNotificacoesNovas += not2.novo ? 1 : 0;
      // this.notificacoes.push(not2);

      // let dtNot3 = new Date();
      // dtNot3.setMinutes(now.getMinutes() - 1)
      // let tempoDecNot3 = this._dataUtils.formatTimeElapsed(dtNot3, now);
      // let not3 = {
      //   titulo: "Recomendação de Excelência Operacional",
      //   texto: "Aprovação de processo pendente",
      //   // criticidade:  tempoDecNot3.tempoDecorridoMS >= umMinMs ? 3 :
      //   //               tempoDecNot3.tempoDecorridoMS >= umMinMs * 2 ? 5 : 7,
      //   criticidade:  5,
      //   novo: false,
      //   quantidade: 1,
      //   quantidadeNovas: 1,
      //   data: dtNot3,
      //   tempoDecorrido: tempoDecNot3,
      //   icone: "excelencia",
      //   cor: "--color-accent",
      // };

      // this.numeroNotificacoesNovas += not3.novo ? 1 : 0;
      // this.notificacoes.push(not3);

      // let dtNot4 = new Date();
      // dtNot4.setMinutes(now.getMinutes() - 1)
      // let tempoDecNot4 = this._dataUtils.formatTimeElapsed(dtNot4, now);
      // let not4 = {
      //   titulo: "Recomendação de Excelência Operacional",
      //   texto: "Aprovação de processo pendente",
      //   criticidade:  3,
      //   novo: true,
      //   quantidade: 1,
      //   quantidadeNovas: 1,
      //   data: dtNot4,
      //   tempoDecorrido: tempoDecNot4,
      //   icone: "excelencia",
      //   cor: "--color-accent",
      // };

      // this.numeroNotificacoesNovas += not4.novo ? 1 : 0;
      // this.notificacoes.push(not4);
    } catch (err) {
      let msg = typeof err.error == "string" ? err.error : err.message;
      this._msgSrv.showErrorMessage({
        msg: msg,
        tit: "Erro inesperado - Recuperar novas notificações",
      });
      throw new Error(msg);
    }
  }

  navegar(rota, params = null) {
    this._router.navigate([rota], {
      queryParams: params,
    });
  }

  getIcone(tipo) {
    return this.envDecorators["NOTIFICACAO"]["ICONE"][tipo];
  }

  getTitulo(tipo) {
    return this.envDecorators["NOTIFICACAO"]["TITULO"][tipo];
  }

  async visualizarNovas() {
    //if (this.numeroNotificacoesNovas > 0) {
      await this._recomensacaoActServ.visualizarNovas();
    //}
    //this.numeroNotificacoesNovas = 0;
  }

  abrirNotification(state: boolean) {
    console.log(state);

    this.showNotification = state;
  }
}
