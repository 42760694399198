import { Component, OnInit } from '@angular/core';
import { ErpService } from '@services/erp.service';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-modal-erp-off',
  templateUrl: './modal-erp-off.component.html',
  styleUrls: ['./modal-erp-off.component.scss']
})
export class ModalErpOffComponent implements OnInit {

  // Atributos básicos com valores padrões
  loader = false;
  private _headerErro = `Não foi possível estabelecer uma conexão com o ERP.
  Você pode continuar a utilizar a aplicação, porém com recursos limitados, enquanto 
  o InvoiceCon tenta, em segundo plano, reestabelecer a comunicação com o ERP.
  Em caso de dúvidas entre em contato com a administração e informe o seguinte erro:`;
  private _headerOk = `A conexão com o ERP foi reestabelecida.`;
  testeRealizado = false;

  constructor(
    private _erpSrv: ErpService,
    public dialogRef: MatDialogRef<ModalErpOffComponent>
  ) { }

  ngOnInit() {}

  get status(): string {
    let status = 'Erro inesperado';
    if (this._erpSrv.statusErp) {
      switch (this._erpSrv.statusErp.status) {
        case 'erro':
          status = this._erpSrv.statusErp.msg || 'Erro inesperado de conexão com ERP';
          break;
        case 'ok':
          status = 'Conexão com ERP estabelecida com sucesso';
          break;
        case 'conectando':
          status = 'Tentando conectar com ERP';
          break;
        default:
          status = 'Erro inesperado de conexão com ERP';
      }
    }
    return status;
  }

  get statusClass(): string {
    let statusClass = 'erp-erro';
    if (this._erpSrv.statusErp) {
      switch (this._erpSrv.statusErp.status) {
        case 'ok':
          statusClass = 'erp-ok';
          break;
        case 'erro':
          statusClass = 'erp-erro';
          break;
        case 'conectando':
          statusClass = 'erp-conectando';
          break;
        default:
          statusClass = 'erp-erro';
      }
    }
    return statusClass;
  }

  get testResult(): string {
    let resultado = '';
    if (this.testeRealizado) {
      resultado = 'Teste realizado - Erro de conexão';
      if (this._erpSrv.statusErp) {
        switch (this._erpSrv.statusErp.status) {
          case 'ok':
            resultado = 'Teste realizado - Conexão com ERP bem sucedida';
            break;
          case 'erro':
            resultado = 'Teste realizado - Erro de conexão com ERP';
            break
          case 'conectando':
            resultado = '';
            break
          default:
            resultado = 'Teste realizado - Erro de conexão';
        }
      }
    }
    return resultado;
  }

  get textoHeader(): string {
    let header = this._headerErro;
    if (this._erpSrv.statusErp) {
      switch (this._erpSrv.statusErp.status) {
        case 'ok':
          header = this._headerOk;
          break;
        case 'erro':
          header = this._headerErro;
          break;
        default:
          header = this._headerErro;
      }
    }
    return header;
  }

  /**
   * Realiza a verificação do ERP
   */
  async verificarErp(): Promise<void> {
    this.loader = true;
    // let status = 'erro';
    // if (this._erpSrv.statusErp) {
    //   status = this._erpSrv.statusErp.status || 'erro';
    // }
    try {
      await this._erpSrv.checkConexaoErp('erro')
    } catch(err) {
      
    } finally {
      this.loader = false;
      this.testeRealizado = true;
    }
  }

}
