import { ErpService } from '@services/erp.service';
import { Component, OnInit } from "@angular/core";
import { BroadcastService, MsalService } from "@azure/msal-angular";
import { Router } from "@angular/router";
import { AuthService } from "./services/auth.service";
import { SecureLocalStorage } from "@services/storage/secure-local-storage";
import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from "@angular/platform-browser";
import { EnvService } from '@services/env.service';

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
  title = "app-angular";
  constructor(
    iconRegistry: MatIconRegistry,
    sanitizer: DomSanitizer,
    private _broadcastService: BroadcastService,
    private router: Router,
    private _msadalServ: MsalService,
    private _authSrv: AuthService,
    private secureLocalStorage: SecureLocalStorage,
    private _erpSrv: ErpService,
  ) {
    // Registra ícones globalmente
    iconRegistry.addSvgIcon("add_link", sanitizer.bypassSecurityTrustResourceUrl(EnvService.assetsUrl("assets/img/add_link.svg")));
    iconRegistry.addSvgIcon("assignment", sanitizer.bypassSecurityTrustResourceUrl(EnvService.assetsUrl("assets/img/assignment.svg")));
    iconRegistry.addSvgIcon("assignment_turned_in", sanitizer.bypassSecurityTrustResourceUrl(EnvService.assetsUrl("assets/img/assignment_turned_in.svg")));
    iconRegistry.addSvgIcon("attachment", sanitizer.bypassSecurityTrustResourceUrl(EnvService.assetsUrl("assets/img/attachment.svg")));
    iconRegistry.addSvgIcon("buscaPedido", sanitizer.bypassSecurityTrustResourceUrl(EnvService.assetsUrl("assets/img/find_in_page.svg")));
    iconRegistry.addSvgIcon("check", sanitizer.bypassSecurityTrustResourceUrl(EnvService.assetsUrl("assets/img/check.svg")));
    iconRegistry.addSvgIcon("clear", sanitizer.bypassSecurityTrustResourceUrl(EnvService.assetsUrl("assets/img/clear.svg")));
    iconRegistry.addSvgIcon("close", sanitizer.bypassSecurityTrustResourceUrl(EnvService.assetsUrl("assets/img/close.svg")));
    iconRegistry.addSvgIcon("compare", sanitizer.bypassSecurityTrustResourceUrl(EnvService.assetsUrl("assets/img/compare.svg")));
    iconRegistry.addSvgIcon("create", sanitizer.bypassSecurityTrustResourceUrl(EnvService.assetsUrl("assets/img/create.svg")));
    iconRegistry.addSvgIcon("delete", sanitizer.bypassSecurityTrustResourceUrl(EnvService.assetsUrl("assets/img/delete.svg")));
    iconRegistry.addSvgIcon("doca", sanitizer.bypassSecurityTrustResourceUrl(EnvService.assetsUrl("assets/img/doca.svg")));
    iconRegistry.addSvgIcon("done", sanitizer.bypassSecurityTrustResourceUrl(EnvService.assetsUrl("assets/img/done.svg")));
    iconRegistry.addSvgIcon("download", sanitizer.bypassSecurityTrustResourceUrl(EnvService.assetsUrl("assets/img/download.svg")));
    iconRegistry.addSvgIcon("edit", sanitizer.bypassSecurityTrustResourceUrl(EnvService.assetsUrl("assets/img/edit.svg")));
    iconRegistry.addSvgIcon("error", sanitizer.bypassSecurityTrustResourceUrl(EnvService.assetsUrl("assets/img/error.svg")));
    iconRegistry.addSvgIcon("history", sanitizer.bypassSecurityTrustResourceUrl(EnvService.assetsUrl("assets/img/history.svg")));
    iconRegistry.addSvgIcon("hourglass", sanitizer.bypassSecurityTrustResourceUrl(EnvService.assetsUrl("assets/img/hourglass.svg")));
    iconRegistry.addSvgIcon("interpretador", sanitizer.bypassSecurityTrustResourceUrl(
      EnvService.assetsUrl("assets/img/interpretador.svg")));
      iconRegistry.addSvgIcon("label", sanitizer.bypassSecurityTrustResourceUrl(EnvService.assetsUrl("assets/img/label.svg")));
    iconRegistry.addSvgIcon("left", sanitizer.bypassSecurityTrustResourceUrl(EnvService.assetsUrl("assets/img/left.svg")));
    iconRegistry.addSvgIcon("link", sanitizer.bypassSecurityTrustResourceUrl(EnvService.assetsUrl("assets/img/link.svg")));
    iconRegistry.addSvgIcon("local_shipping", sanitizer.bypassSecurityTrustResourceUrl(
      EnvService.assetsUrl("assets/img/local_shipping.svg")));
    iconRegistry.addSvgIcon("lock", sanitizer.bypassSecurityTrustResourceUrl(EnvService.assetsUrl("assets/img/lock.svg")));
    iconRegistry.addSvgIcon("massprocess", sanitizer.bypassSecurityTrustResourceUrl(EnvService.assetsUrl("assets/img/mass.svg")));
    iconRegistry.addSvgIcon("menu", sanitizer.bypassSecurityTrustResourceUrl(EnvService.assetsUrl("assets/img/menu.svg")));
    iconRegistry.addSvgIcon("menu_open", sanitizer.bypassSecurityTrustResourceUrl(EnvService.assetsUrl("assets/img/menu_open.svg")));
    iconRegistry.addSvgIcon("newfile", sanitizer.bypassSecurityTrustResourceUrl(EnvService.assetsUrl("assets/img/add.svg")));
    iconRegistry.addSvgIcon("open", sanitizer.bypassSecurityTrustResourceUrl(EnvService.assetsUrl("assets/img/open.svg")));
    iconRegistry.addSvgIcon("ordenador", sanitizer.bypassSecurityTrustResourceUrl(EnvService.assetsUrl("assets/img/ordenador.svg")));
    iconRegistry.addSvgIcon("pdf", sanitizer.bypassSecurityTrustResourceUrl(EnvService.assetsUrl("assets/img/pdf.svg")));
    iconRegistry.addSvgIcon("playlist_add", sanitizer.bypassSecurityTrustResourceUrl(EnvService.assetsUrl("assets/img/playlist_add.svg")));
    iconRegistry.addSvgIcon("plus", sanitizer.bypassSecurityTrustResourceUrl(EnvService.assetsUrl("assets/img/plus.svg")));
    iconRegistry.addSvgIcon("portaria", sanitizer.bypassSecurityTrustResourceUrl(EnvService.assetsUrl("assets/img/portaria.svg")));
    iconRegistry.addSvgIcon("print", sanitizer.bypassSecurityTrustResourceUrl(EnvService.assetsUrl("assets/img/print.svg")));
    iconRegistry.addSvgIcon("processando", sanitizer.bypassSecurityTrustResourceUrl(EnvService.assetsUrl("assets/img/processando.svg")));
    iconRegistry.addSvgIcon("restore_from_trash", sanitizer.bypassSecurityTrustResourceUrl(EnvService.assetsUrl("assets/img/restore_from_trash.svg")));
    iconRegistry.addSvgIcon("right", sanitizer.bypassSecurityTrustResourceUrl(EnvService.assetsUrl("assets/img/right.svg")));
    iconRegistry.addSvgIcon("search", sanitizer.bypassSecurityTrustResourceUrl(EnvService.assetsUrl("assets/img/search.svg")));
    iconRegistry.addSvgIcon("thumb_up", sanitizer.bypassSecurityTrustResourceUrl(EnvService.assetsUrl("assets/img/thumb_up.svg")));
    iconRegistry.addSvgIcon("upload", sanitizer.bypassSecurityTrustResourceUrl(EnvService.assetsUrl("assets/img/upload.svg")));
    iconRegistry.addSvgIcon("warning", sanitizer.bypassSecurityTrustResourceUrl(EnvService.assetsUrl("assets/img/warning.svg")));
    iconRegistry.addSvgIcon("xml", sanitizer.bypassSecurityTrustResourceUrl(EnvService.assetsUrl("assets/img/xml.svg")));
    iconRegistry.addSvgIcon("business_hand", sanitizer.bypassSecurityTrustResourceUrl(
      EnvService.assetsUrl("assets/img/business_hand.svg")));
    iconRegistry.addSvgIcon("keyboard", sanitizer.bypassSecurityTrustResourceUrl(EnvService.assetsUrl("assets/img/keyboard.svg")));
    iconRegistry.addSvgIcon("keyboard_external_input",
      sanitizer.bypassSecurityTrustResourceUrl(EnvService.assetsUrl("assets/img/keyboard_external_input.svg")));
    iconRegistry.addSvgIcon("keyboard_off", sanitizer.bypassSecurityTrustResourceUrl(EnvService.assetsUrl("assets/img/keyboard_off.svg")));
  }

  async ngOnInit() {
    /**
     * Se o usuário já estiver logado
     */
    if (this._msadalServ.getAccount()) {
      this._broadcastService.broadcast("msal:loginSuccess", null);
    } else {
      this._msadalServ.loginPopup();
    }

    this._msadalServ.handleRedirectCallback((authError, response) => {
      if (authError) {
        this._broadcastService.broadcast("msal:loginFailure", response);
        return;
      }

      this._broadcastService.broadcast("msal:loginSuccess", response);
    });

    this._broadcastService.subscribe("msal:loginSuccess", async (payload) => {
      this._authSrv.login(this.router["location"].path());

      // Redireciona para a tela de login
      this.router.navigateByUrl("/");
    });

    /**
     * Ação em caso de falha do login (usuário não logado)
     */
    this._broadcastService.subscribe("msal:loginFailure", (payload) => {
      let navigation = this.router.getCurrentNavigation();

      if (navigation) {
        this.secureLocalStorage.setItem(
          "urlRedirectLogin",
          navigation.extractedUrl.toString()
        );
      }

      this._msadalServ.loginRedirect();
    });
  }

  /**
   * Função para mudar o tema.
   */
  mudarTema(tema: string) {
    this.trans();
    document.documentElement.setAttribute("data-theme", tema);
    localStorage.setItem("temaAplicacao", tema);
  }

  /**
   * Função para fazer a mudança de tema com transição
   */
  trans() {
    document.documentElement.classList.add("transition");
    window.setTimeout(() => {
      document.documentElement.classList.remove("transition");
    }, 600);
  }
}
