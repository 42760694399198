<section class="login">
    <div class="card-login">
        <form action="" fxLayout="column" fxLayoutAlign="center center">
            <span class="container-logo-login">
                    <img src="{{logoUrl}}" style="width: 236px;">
            </span>
            <div>
                <span fxFlex="100%" fxLayoutAlign="center">Aguarde enquanto realizamos processo de login</span>
            </div>
            <div class="spinner">
                <div>
                    <mat-spinner></mat-spinner>
                </div>
            </div>
        </form>
    </div>
</section>
