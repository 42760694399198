import { IEntidadeFacade } from '../services/facade/entidade.interface'

export class Notificacao {
 
  titulo:  string;
  texto:  string;
  data: Date;
  novo: Boolean;
  tempoDecorrido: {
    tempoDecorrido: null,
    tempoDecorridoMS: null,
    tempoTooltip: null,
  };
  criticidade: string;
  quantidade: number;
  quantidadeNovas: number;
  icone: string;
  cor: string;

}