import { ModuleWithProviders, NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { Configuration } from "msal";
import {
  MsalAngularConfiguration,
  MsalModule,
  MsalService,
  MSAL_CONFIG,
  MSAL_CONFIG_ANGULAR,
} from "@azure/msal-angular";
import { environment } from "src/environments/environment";
import { EnvService } from "@services/env.service";

export function MSALConfigFactory(): Configuration {
  const isIE =
    window.navigator.userAgent.indexOf("MSIE ") > -1 ||
    window.navigator.userAgent.indexOf("Trident/") > -1;

  const redirectUri = window.location.href.split('#')[0];

  return {
    auth: {
      clientId: environment.azure_enterprise_application,
      authority: `https://login.microsoftonline.com/${environment.azure_ad_tenant_id}`,
      redirectUri,
      postLogoutRedirectUri: null,
    },
    cache: {
      cacheLocation: "localStorage",
      storeAuthStateInCookie: isIE, // set to true for IE 11
    },
  };
}

export function MSALAngularConfigFactory(): MsalAngularConfiguration {
  const protectedResourceMap: [string, string[]][] = [
    [`${environment.api_endpoint}/api`, [`api://${environment.azure_enterprise_application}/user_impersonation`]],
    ["/assets", [`api://${environment.azure_enterprise_application}/user_impersonation`]],
  ];

  const isIE =
    window.navigator.userAgent.indexOf("MSIE ") > -1 ||
    window.navigator.userAgent.indexOf("Trident/") > -1;

  return {
    popUp: !isIE,
    consentScopes: [`${environment.azure_enterprise_application}/user_impersonation`],
    protectedResourceMap: protectedResourceMap,
    extraQueryParameters: {},
  };
}

@NgModule({
  imports: [CommonModule, MsalModule],
})
export class MsalApplicationModule {
  static forRoot(): ModuleWithProviders<MsalApplicationModule> {
    return {
        ngModule: MsalApplicationModule,
        providers: [
            {
                provide: MSAL_CONFIG,
                useFactory: MSALConfigFactory,
            },
            {
                provide: MSAL_CONFIG_ANGULAR,
                useFactory: MSALAngularConfigFactory,
            },
            MsalService,
        ],
    };
}
}
