import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, UrlTree } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { EnvService } from '../services/env.service';
import { MsalService } from '@azure/msal-angular';
import { Injectable } from '@angular/core';
import { ClienteService } from '@services/cliente.service';

@Injectable({
  providedIn: "root",
})
export class LoadDataGuardService implements CanActivate {

  private _loading: boolean = false;

  constructor(
    private _msadalServ: MsalService,
    private _authSrv: AuthService,
    public _clienteSrv: ClienteService,
    private _router: Router,
  ) {
  }

  public get loading() {
    return this._loading;
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean|UrlTree {
    // Verifica se está logado no AD
    let account = this._msadalServ.getAccount();
    if (!account) {
      sessionStorage.setItem("urlRedirectLogin", state.url);
      this._router.parseUrl("/");
    }

    // Verifica se está logado na aplicação
    if (!this._authSrv.isLoggedIn) {
      // Se não está logado é porque o método login do AuthService ainda não foi executado
      // Neste caso ignoramos esta rota porque o próprio método de login irá redirecionar
      //   para o destino correto
      return false;
    }

    return this.checkHome(route);
  }

  checkHome(route: ActivatedRouteSnapshot): boolean {
    this._loading = false;
    const url = route.routeConfig.path;
    let redirectTo;
    let valid = true;

    if (url == 'home' && !this._authSrv.can('visualizar_home')) {
      valid = false;
      if (this._authSrv.can('visualizar_doca')) {
        redirectTo = '/recebimento';
      }
      if (this._authSrv.can('visualizar_curadoria')) {
        redirectTo = '/recomendacao';
      }
      // Redireciona para a página default pois não encontrou a página ideal:
      if (!redirectTo) {
        redirectTo = '/default';
      }
    }

    if (redirectTo) {
      EnvService.redirectHref(redirectTo)
    }

    return valid;
  }
}
