import { ErpService } from "@services/erp.service";
import { ActivatedRoute } from "@angular/router";
import { Router } from "@angular/router";
import { ErroService } from "@services/erro.service";
import { Injectable, Injector } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
  HttpResponse,
} from "@angular/common/http";
import { Observable, of, throwError } from "rxjs";
import { MsalService } from "@azure/msal-angular";
import { finalize, catchError, retry, tap, timeout } from "rxjs/operators";
import { LoaderService } from "../services/loader.service";
import { ToastrService } from "ngx-toastr";
import { from } from "rxjs";
import { mergeMap } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class TokenInterceptService implements HttpInterceptor {
  constructor(
    private _toastr: ToastrService,
    private _msadalServ: MsalService,
    private injector: Injector,
    private _erroSrv: ErroService,
    private _router: Router,
    private _route: ActivatedRoute,
    private _erpSrv: ErpService
  ) { }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // Se for request para o erp, sinaliza que está conectando
    if (
      request.url.includes("erp/") ||
      request.url.includes("avancar") ||
      request.url.includes("retornar")
    ) {
      this._erpSrv.checkConexaoErp("conectando");
    }
    const loaderService = this.injector.get(LoaderService);
    var scopes = this._msadalServ.getScopesForEndpoint(request.url);

    if (!scopes || request.url.indexOf("assets") >= 0) {
      return next.handle(request);
    }

    /**
     * Se o usuário não estiver logado
     */
    if (!this._msadalServ.getAccount()) {
      throw new Error("Usuário não logado");
    }

    return from(
      this._msadalServ
        .acquireTokenSilent({ scopes: scopes })
        .then((response) => {
          return this.saveToken(request, response);
        })
    ).pipe(
      catchError((err: any) => {
        // console.error(err)

        // Corrigindo erro de parse do cache no MSAL
        if (err.errorCode == "cannot_parse_cache") {
          const items = { ...localStorage };
          Object.keys(items).forEach((key) => {
            if (
              key.indexOf("msal.") == 0 &&
              key.indexOf(".acquireTokenAccount") >= 0
            ) {
              localStorage.removeItem(key);
            }
          });
        }

        // Salva a URL que estamos acessando
        let urlTemp = sessionStorage.getItem("urlRedirectLogin");
        if (!urlTemp) {
          urlTemp = window.location.href.split("#")[1];
          sessionStorage.setItem("urlRedirectLogin", urlTemp);
        }

        this._msadalServ.acquireTokenRedirect({ scopes: scopes });
        return throwError(err);
      }),
      mergeMap((nextReq) => {
        // Ativa o loader
        loaderService.show();

        return next.handle(nextReq).pipe(
          timeout(300000),
          // Desativa novas tentativas em caso de erro
          retry(0),
          tap((dados) => {
            // Verifica se os dados são do tipo HttpResponse, pois aí é sinal que a requisição foi bem sucedida
            if (dados && dados instanceof HttpResponse) {
              // Verifica se a URL contém string que referencie o ERP
              if (
                (dados.url && dados.url.includes("erp/")) ||
                (dados.url && dados.url.includes("avancar")) ||
                (dados.url && dados.url.includes("retornar"))
              ) {
                // Se tiver, informa o ErpService que o status está OK
                this._erpSrv.checkConexaoErp("ok");
              }
            }
          }),

          // Tratamento de erros
          catchError((error: any) => {
            loaderService.hide();
            // Erro de comunicação com o ERP
            this._erpSrv.checkConexaoErp("ok");

            if (error instanceof HttpErrorResponse && error.status === 426) {
              // Código para verificação da versão do ERP do cliente em comparação com a versão requerida
              let urlTentarNovamente = window.location.href
                .split("#")[1]
                .split("?")[0];
              let paramsTentarNovamente = null;
              if (this._route.snapshot.queryParams) {
                paramsTentarNovamente = this._route.snapshot.queryParams;
              }

              this._erroSrv.eventoErro(
                "ERRVERS",
                `A versão do InvoiceCon instalada no ERP (${error.error.versaoCliente}) é inferior à versão requerida (${error.error.versaoRequerida}).`,
                "login",
                "Verificar novamente",
                urlTentarNovamente,
                paramsTentarNovamente,
                "Solicite a atualização do ERP para poder acessar o InvoiceCon."
              );
              this._router.navigate(["/erro"]);

              return throwError(
                "Versão atual do ERP é incompatível com a versão requerida pelo InvoiceCon."
              );

            } else if (
              error instanceof HttpErrorResponse &&
              error.status !== 503 &&
              (error.url.includes("erp/") ||
                (error.error &&
                  typeof error.error === "string" &&
                  error.error.includes("ERP")))
            ) {
              // Verifica se a url não é a url de verificação de versão
              if (!error.url.includes("erp/checkComunicacaoErp")) {
                // Erro de comunicação com o ERP
                this._erpSrv.checkConexaoErp("erro");
              }
            } else if (
              error instanceof HttpErrorResponse &&
              error.status === 503
            ) {
              this._erroSrv.eventoErro(
                "AVMANUT",
                `O InvoiceCon encontra-se em manutenção!`,
                "manutencao",
                "Verificar novamente",
                "home",
                null,
                "Aguarde alguns instantes ou entre em contato com o administrador.",
                null,
                "AVISO",
                "Código do aviso",
              );
              this._router.navigate(["/erro"]);
            } else if (
              error instanceof HttpErrorResponse &&
              error.status === 403
            ) {
              this._toastr.error("Você não possui autorização para esta ação!");
            } else if (
              error instanceof HttpErrorResponse &&
              error.status === 401
            ) {
              // this._msadalServ.clearCacheForScope();
            } else if (
              error instanceof HttpErrorResponse &&
              error.status === 400 &&
              error.error &&
              error.error.error &&
              error.error.error.message
            ) {
              this._toastr.error(error.error.error.message);
            } else if (
              error instanceof HttpErrorResponse &&
              error.error &&
              error.error.message
            ) {
              // Melhoria nas mensagens de erro
              return throwError(
                new HttpErrorResponse({
                  error: error.error.message,
                  status: error.status,
                })
              );
            }

            return throwError(error);
          }),

          // Desativa o loader
          finalize(() => loaderService.hide())
        );
      })
    );
  }

  saveToken(request, response) {
    let token =
      response.tokenType === "id_token"
        ? response.idToken.rawIdToken
        : response.accessToken;

    if (request.url.indexOf("upload") < 0) {
      return request.clone({
        headers: request.headers
          .set("Content-Type", "application/json")
          .set("Authorization", `Bearer ${token}`),
      });
    } else {
      return request.clone({
        headers: request.headers.set("Authorization", `Bearer ${token}`),
      });
    }
  }
}
