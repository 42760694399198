import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { CrudService } from "./crud.service";
import { switchMap } from 'rxjs/operators';
import { Observable } from 'rxjs';

export interface Tolerancia {
  uuid: string;
  uuid_cliente: string;
  fluxo: string;
  tipo_processo: string;
  tipo_tolerancia: string;
  tolerancia: any;
}

@Injectable({
  providedIn: 'root'
})
export class FluxoToleranciaService extends CrudService {
  // Tolerâncias
  public tolerancias: Tolerancia[];
  // Ultima checagem
  private lastCheck = Date.now();

  constructor(protected httpClient: HttpClient) {
    super(httpClient);
  }

  /**
   * Endpoint do serviço
   */
  get apiEndpoint(): string {
    return "fluxo-tolerancia";
  }

  /**
   * Método para recuperar tolerância de fluxo
   *
   * @param processo any
   * @returns any
   */
  getByProcesso(processo?: string): Observable<Tolerancia[]> {
    // Caso seja uma requisição valida para listagem
    if (this.validRequestList) {
      const params = new HttpParams().set('processo', processo);
      // Lista
      return this.list(params);
    }
    // Retorna um observable do cache
    return this.getToleranciaAtual(processo);
  }

  /**
   * Método para regastar o cache de tolerâncias filtrado ou não
   * @param processo tipo de processo
   * @returns observable
   */
  getToleranciaAtual(processo?: string): Observable<Tolerancia[]> {
    return new Observable((observer: any) => {
      // Cópia do objeto de tolerâncias
      let filteredTolerancia = [...this.tolerancias];
      // Caso tenha o filtro do tipo de processo
      if (processo) {
        // Filtra dentro do cache o tipo de processo selecionado
        filteredTolerancia = filteredTolerancia.filter((tol) => tol.tipo_processo === processo);
      }
      // Lança a resposta para o observer
      observer.next(filteredTolerancia);
      // Completa o observer
      observer.complete();
    });
  }

  /**
   * Verifica se há necessidade de enviar requisição de lista
   */
  get validRequestList(): boolean {
    // Data atual em millisegundos
    const date = Date.now();
    // 5 minutos em millisegundos
    const interval = 60 * 1000 * 5;
    // Caso não tenha tolerancia ou se a data da última checagem já ultrapassou 5 minutos
    if (!this.tolerancias || date > this.lastCheck + interval) {
      // Atualiza a ultima checagem para data atual
      this.lastCheck = Date.now();
      // Retorna verdadeiro para enviar a requisição ao backend
      return true;
    }
  }

  /**
   * Método para atualizar uma entidade
   *
   * @param body corpo
   * @param id id
   * @returns observer
   */
  update(body: any, id: any) {
    return super.update(body, id).pipe(switchMap(r => this.init(true)));
  }

  /**
   * Método para excluir uma entidade
   *
   * @param id id
   * @returns observer
   */
  delete(id: any) {
    return super.delete(id).pipe(switchMap(r => this.init(true)));

  }

  /**
   * Método que inicializa a lista de tolerâncias
   * @returns lista
   */
  public async init(force?: boolean) {
    // Envia a requisição caso esteja sendo forçado, ou caso seja uma request válida
    if (force || this.validRequestList) {
      return this.list(null).subscribe(
        tolerancias => {
          this.tolerancias = tolerancias;
        });
    }
  }
}
