<h2 mat-dialog-title>Sobre o InvoiceCon</h2>

<!-- <p>O InvoiceCon é uma suíte de aplicativos que visa melhorar a produtividade na execução,</p>
<p>a visibilidade das atividades necessárias e a qualidade dos dados gerados nos </p>
<p>processos de escrituração dos principais tipos de documentos fiscais de entrada</p>
<p>presentes no dia a dia das empresas.</p>
<br>

<p>Além disso, possibilita o acompanhamento dos processos de entrada individualmente, </p>
<p>desde o momento da disponibilização do documento fiscal pelo emitente até a escrituração </p>
<p>e registro no contas a pagar da empresa destinatária. Ou seja, trata-se de um add-on de ERP,</p>
<p>que funciona em conjunto com o seu sistema de gestão empresarial.</p>
<br> -->

<div id="loadingSobre" *ngIf="!versao">
  <app-loading-indicator
    [texto]="'Carregando versões'"
    [determinado]="false"
    [textoIntermitente]="true"
  >
  </app-loading-indicator>
</div>

<p *ngIf="versao" style="font-weight: bold; text-align: center">
  Versão do produto
</p>
<table border="1" *ngIf="versao" style="align-content: center; width: 100%">
  <thead>
    <tr>
      <td><span style="font-weight: bold">Módulo</span></td>
      <td><span style="font-weight: bold">Versão</span></td>
    </tr>
  </thead>
  <tbody>
    <tr *ngIf="versao.interface">
      <td>Interface</td>
      <td>{{ versao.interface }}</td>
    </tr>
    <tr *ngIf="versao.api">
      <td>API</td>
      <td>{{ versao.api }}</td>
    </tr>
    <tr *ngIf="versao.erp">
      <td>ERP</td>
      <td>{{ versao.erp }}</td>
    </tr>
    <tr *ngIf="versao.interpretador">
      <td>Interpretador</td>
      <td>{{ versao.interpretador }}</td>
    </tr>
  </tbody>
</table>

<br />

<p *ngIf="versao" style="font-weight: bold; text-align: center">
  Módulos habilitados
</p>
<table border="1" *ngIf="versao" style="align-content: center; width: 100%">
  <thead>
    <tr>
      <td style="font-weight: bold">Módulo</td>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let modulo of modulos">
      <td>
        {{ modulo.nome }}
      </td>
    </tr>
  </tbody>
</table>
<mat-dialog-actions align="end" *ngIf="versao">
  <button
    mat-flat-button
    color="primary"
    [mat-dialog-close]="true"
    cdkFocusInitial
  >
    Fechar
  </button>
</mat-dialog-actions>
