import * as moment from "moment";

export class DataUtils {


  constructor() { 

  }

  formatTimeElapsed(data_inicio, data_fim) {
    //Formata Tempo decorrido
    let startDate = moment.utc(data_inicio);
    let endDate = moment.utc(data_fim);
    let element = {
      tempoDecorrido: null,
      tempoDecorridoMS: null,
      tempoTooltip: null,
    };

    // Valores fixos para comparação (valores em ms)
    const minuto = 60000;
    const hora = 3600000;
    const dia = 86400000;

    if (!data_fim) {
      endDate = moment();
    }
      // Calcula o tempo decorrido em ms
      element.tempoDecorridoMS = endDate.diff(startDate);

      // A partir do tempo decorrido em ms calcula se é em segundos, minutos, horas ou dias
      if (element.tempoDecorridoMS < minuto) {
        element.tempoDecorrido = Math.abs(endDate.diff(startDate, "seconds"));
        if (element.tempoDecorrido == 1) {
          element.tempoTooltip = `${element.tempoDecorrido} segundo`;
        } else {
          element.tempoTooltip = `agora`;
        }
        element.tempoDecorrido = `${element.tempoDecorrido} s`;
      } else if (element.tempoDecorridoMS < hora) {
        element.tempoDecorrido = endDate.diff(startDate, "minutes");
        if (element.tempoDecorrido == 1) {
          element.tempoTooltip = `${element.tempoDecorrido} minuto`;
        } else {
          element.tempoTooltip = `${element.tempoDecorrido} minutos`;
        }
        element.tempoDecorrido = `${element.tempoDecorrido} m`;
      } else if (element.tempoDecorridoMS < dia) {
        element.tempoDecorrido = endDate.diff(startDate, "hours");
        if (element.tempoDecorrido == 1) {
          element.tempoTooltip = `${element.tempoDecorrido} hora`;
        } else {
          element.tempoTooltip = `${element.tempoDecorrido} horas`;
        }
        element.tempoDecorrido = `${element.tempoDecorrido} h`;

      } else {
        element.tempoDecorrido = endDate.diff(startDate, "days");
        if (element.tempoDecorrido == 1) {
          element.tempoTooltip = `${element.tempoDecorrido} dia`;
        } else {
          element.tempoTooltip = `${element.tempoDecorrido} dias`;
        }
        element.tempoDecorrido = `${element.tempoDecorrido} d`;
      }
    
    return element;
  }

  static ComparadorData(d1, d2) {

    let d1F = moment(d1['createdDateTime']);
    let d2F = moment(d2['createdDateTime']);

    if (d1F.valueOf() > d2F.valueOf()) return -1;
    if (d1F.valueOf() < d2F.valueOf()) return 1;
    return 0;
  }

  formatDateTimeToLocale(date: any) {
    return moment(date).format("DD/MM/YYYY HH:mm");
  }

  formatDateToLocale(date: any) {
    return moment(date).format("DD/MM/YYYY");
  }

  formatDateToPattern(date: any, pattern: string) {
    return moment(date).format(pattern);
  }

}
