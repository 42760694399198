
export const constants_decorators = {

    "TEMPO_MS":{
        "UM_SEGUNDO": 1000,
        "UM_MINUTO" : 60000,
        "DEZ_MINUTOS" : 600000
    },
    "DOCUMENTO":{
        "TIPO": {
            "NFSE" : "NFSE",
            "CTE" : "CTE",
            "NFE" : "NFE"
        }
    },
    "ARQUIVO":{
        "TIPO": {
            "PDF" : {
                "NOME": "PDF",
                "EXTENSAO" : ".pdf"
            },
            "XML" : {
                "NOME": "XML",
                "EXTENSAO" : ".xml"
            }
        },
    },
    "VALOR":{
        "VAZIO" : "",
        "SIM" : "S",
        "NAO" : "N"
    },
    "VALOR_NOMINAL":{
        "VAZIO" : "",
        "SIM" : "Sim",
        "NAO" : "Não"
    }
    ,"CAMPO_SISTEMA":{
        "UUID" : "uuid",
        "NOME" : "nome",
        "UUID_USUARIO_CREATED" : "uuid_usuario_created",
        "NOME_USUARIO_CREATED" : "nome_usuario_created",
        "UUID_USUARIO_UPDATED" : "uuid_usuario_updated",
        "NOME_USUARIO_UPDATED" : "nome_usuario_updated",
        "CREATED_AT" : "created_at"
    }
    ,"CACHE":{
        "ID_PROCESSO" : "idprocesso",
        "PORTARIA" : "portaria",
        "DOCA" : "doca",
        "PENDENCIA_CURADORIA": "pendcuradoria"
    }
    ,"LOG": {
        "LEVEL" : {

            "SUCESS":  10,
            "INFO":    20,
            "WARN":    30,
            "ERROR":   40
        }
    }
    ,"LABELS":{
        "SIM": "Sim",
        "NAO": "Não",
        "ATENCAO": "Atenção"
    }
    ,"MSG":{
        "CONTINUAR": "Deseja continuar?"
    }
    ,"ROTAS":{
        "HOME": "/home"
        ,"API": {
            "V1": "api/v1",
            "V2": "api/v2"
        }
        ,"ENTIDADE": {
            "SISTEMA": "/sis",
            "ID_PROCESSO": "/idprocesso",
            "PORTARIA": "/processo/portaria",
            "DOCUMENTO_DESCARGA": "/processo/docdescarga",
            "DOCA": "/processo/doca",
            "RECOMENDACAO": "/recomendacao",
            "PENDENCIA_CURADORIA": "/curadoria/pendencia"
        }
        ,"ACAO": {
            "SALVAR": "/salvar",
            "RECUPERAR": "/listar",
            "ATUALIZAR": "/atualizar",
            "EXCLUIR": "/excluir",
            "EDITAR": "/editar"
        }
        ,"RECOMENDACAO":{
            "LISTAR" : "/listar",
            "LISTAR_NOVOS" : "/listarnovos",
            "VISUALIZAR_TODAS" : "/visualizartodas"
        }
        ,"CURADORIA":{
            "PENDENCIA":{
                "LISTAR" : "/listar",
                "ATUALIZAR_STATUS" : "/atualizarStatus",
                "CAMPOS" : "/campos"
            }
        }

        ,"ERROS": {
            "NOT_FOUND": "/404"
        }
    }
    ,"CONTEXTOS": {
        "DESCARGA": "DESCARGA",
        "RECOMENDACAO": "RECOMENDACAO",
        "IDENT_PROC": "IDENT_PROC",
        "PENDENCIA_CURADORIA": {
            "RELATORIO": "PENDENCIA_CURADORIA_REL"
        }
    },
    'CRITICIDADE': {
        'BAIXA' : {
            'NOME': 'Baixo',
            'VALOR': '3',
            'COR': 'darkkhaki'
        },
        'MEDIA' : {
            'NOME': 'Médio',
            'VALOR': '5',
            'COR': 'chocolate'
        },
        'ALTA' : {
            'NOME': 'Alto',
            'VALOR': '7',
            'COR': 'brown'
        }
      },
      'EFICIENCIA': {
        'BAIXA' : {
            'NOME': 'Baixa',
            'VALOR': '3',
            'COR': 'brown'
        },
        'MEDIA' : {
            'NOME': 'Média',
            'VALOR': '5',
            'COR': 'chocolate'
        },
        'ALTA' : {
            'NOME': 'Alta',
            'VALOR': '7',
            'COR': 'darkkhaki'
        },
        'OTIMA' : {
            'NOME': 'Ótimo',
            'VALOR': '0',
            'COR': '#4f8a10'
        }
      },
      RECOMENDACAO: {
        "TIPO": {
            "EFI_MIN_INTER": "EFI_MIN_INTER",
            "EFI_TOT_INTER": "EFI_TOT_INTER"
        }
      },
      'CONFIANCA': {
        'BAIXA' : 'baixa',
        'MEDIA' : 'media',
        'ALTA' : 'alta',
      },
      'PENDENCIA_CURADORIA_STATUS': {
        'NOVA' : {
          'VALOR': 1,
          'NOME': 'Nova'
        },
        'ANALISE' : {
          'VALOR': 3,
          'NOME': 'Em Análise'
        },
        'MONITORADA' : {
          'VALOR': 5,
          'NOME': 'Monitorada'
        },
        'DESCONSIDERADA' : {
          'VALOR': 7,
          'NOME': 'Desconsiderada'
        },
        'CONCLUIDA' : {
          'VALOR': 9,
          'NOME': 'Concluída'
        }
    }

};
