import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalErpOffComponent } from './modal-erp-off.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatIconModule } from '@angular/material/icon';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';



@NgModule({
  declarations: [
    ModalErpOffComponent
  ],
  imports: [
    FlexLayoutModule,
    CommonModule,
    MatIconModule,
    MatDialogModule,
    MatButtonModule,
    MatProgressSpinnerModule
  ],
  exports: [
    ModalErpOffComponent
  ]
})
export class ModalErpOffModule { }
