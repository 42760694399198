import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header.component';
import { MatButtonModule } from '@angular/material/button';
import { MatChipsModule } from '@angular/material/chips';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ModalSobreModule } from '@modals/modal-sobre/modal-sobre.module';
import { ModalErpOffModule } from '@modals/modal-erp-off/modal-erp-off.module';
import { NotificationMenuModule } from '@components/notification-menu/notification-menu.module';


@NgModule({
  declarations: [
    HeaderComponent
  ],
  imports: [
    CommonModule,
    MatChipsModule,
    MatTooltipModule,
    MatMenuModule,
    MatIconModule,
    RouterModule,
    MatButtonModule,
    FlexLayoutModule,
    MatDialogModule,
    ModalSobreModule,
    ModalErpOffModule,
    NotificationMenuModule,
  ],
  exports: [
    HeaderComponent
  ]
})
export class HeaderModule { }
