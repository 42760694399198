import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalSobreComponent } from './modal-sobre.component';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { FlexLayoutModule } from '@angular/flex-layout';
import { LoadingIndicatorModule } from '@components/loading-indicator/loading-indicator.module';

@NgModule({
  imports: [
    CommonModule,
    FlexLayoutModule,
    MatDialogModule,
    MatButtonModule,
    LoadingIndicatorModule
  ],
  declarations: [
    ModalSobreComponent
  ],
  exports: [
    ModalSobreComponent
  ]
})
export class ModalSobreModule { }
