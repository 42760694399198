import { Component, OnInit } from "@angular/core";
import { ClienteService, Modulo } from "@services/cliente.service";
import { LoaderService } from "@services/loader.service";
import { Versao, VersaoService } from "@services/versao.service";

@Component({
  selector: "app-modal-sobre",
  templateUrl: "./modal-sobre.component.html",
  styleUrls: ["./modal-sobre.component.scss"],
})
export class ModalSobreComponent implements OnInit {
  public versao: Versao;
  public modulos: Array<Modulo> = [];

  constructor(
    private _versaoSrv: VersaoService,
    public loader: LoaderService,
    private _clienteSrv: ClienteService
  ) {}

  ngOnInit() {
    this._versaoSrv.fetchVersao().then((versao) => {
      this.versao = versao;
    });

    this.modulos = this._clienteSrv.getModulos();
  }
}
