export const recomendacao_decorators = {

    nome: "RECOMENDACAO",

    NOTIFICACAO: {
        ICONE: {
            EFI_TOT_INTER: "curadoria",
            EFI_MIN_INTER: "curadoria",
            EFI_TAZ_INTER: "curadoria"
        },
        TITULO:{
            EFI_TOT_INTER: "Recomendação de Curadoria",
            EFI_MIN_INTER: "Recomendação de Curadoria",
            EFI_TAZ_INTER: "Recomendação de Curadoria"
        }
    }


};
