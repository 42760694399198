
<button mat-button [matMenuTriggerFor]="menuNotifications"
class="mat-menu-trigger mat-button mat-button-base"
(click)="this.visualizarNovas()"
style="display: flex; margin-top: 5px;">
    <span *ngIf="this.notificacoes.length > 0"
    id="mat-badge-content-0" class="mat-badge-medium mat-badge-content mat-badge-active">
    {{this.notificacoes.length}}</span>
    <mat-icon class="icon-inv" svgIcon="notifications" aria-hidden="false" style="cursor:pointer;"></mat-icon>
</button>

<mat-menu #menuNotifications="matMenu" class="mat-menu-panel menu-notificacoes">
    <!-- <button *ngFor="let notificacao of notificacoes" mat-menu-item
        (click)="themeHandler(tema.nome)">{{tema.descricao}}
    </button> -->

    <span *ngIf="this.notificacoes.length > 0" class="menu-cabecalho">
      Você tem {{this.notificacoes.length}} novas recomendações.
    </span>
    <span *ngIf="this.notificacoes.length == 0" class="menu-cabecalho">
      Você não tem novas recomendações.
    </span>
    <a style="text-decoration:none; cursor: pointer;" (click)="navegar('/recomendacoes')">
      <span class="menu-item-badge menu-item-badge-todas"
      >Ver todas
    </span>
    </a>

    <div class="menu-item-overlay">
    <button mat-menu-item *ngFor="let notificacao of notificacoes"
    class="mat-menu-item impacto--{{notificacao.criticidade}}"
    [ngStyle]="{'color': notificacao.cor}"
    >
    <div class="menu-item-content">
      <mat-icon class="icon-inv" svgIcon="{{notificacao.icone}}" aria-hidden="false"
        class="menu-item-icone menu-item-icone-titulo"></mat-icon>
        <div class="menu-item menu-item-titulo">{{notificacao.titulo}}
          <mat-icon *ngIf="this.notificacao.novo"  style="margin-right: 7px;" class="icon-inv" svgIcon="novo" aria-hidden="false"
          class="menu-item-icone menu-item-icone-novo">
        </mat-icon>
          <div *ngIf="this.notificacao.novo"
          class="menu-item-badge menu-item-badge-novo" >Novo</div>
        </div>
        <div class="menu-item menu-item-mensagem">{{notificacao.texto}}</div>
        <div class="menu-item menu-item-badge menu-item-badge-impacto impacto-{{notificacao.criticidade}}">Impacto
          <span *ngIf="notificacao.criticidade == '3'"> Baixo</span>
          <span *ngIf="notificacao.criticidade == '5'"> Médio</span>
          <span *ngIf="notificacao.criticidade == '7'"> Alto</span>
        </div>
        <div class="menu-item menu-item-badge menu-item-badge-tempo">{{notificacao.tempoDecorrido.tempoTooltip}}</div>
      </div>
    </button>
    </div>

</mat-menu>
