export const pendencia_curadoria_rel_decorators = {

    nome: "PENDENCIA_CURADORIA_REL",
    
    numero: "Número",
    serie: "Série",
    data: "Data Emissão",
    cnpj_prestador: "CNPJ Prestador",
    desc_prestador: "Desc. Prestador",
    cnpj_tomador: "CNPJ Tomador",
    desc_tomador: "Des. Tomador",
    codigo_municipio: "Cód. Município",
    pedido_compra: "Pedido Compra",
    desc_item: "Desc. Serviço",
    valor_total: "Valor Total",
    valor_liquido: "Valor Líquido",
    valor_iss: "Valor ISS",
    valor_iss_ret: "Valor ISS Ret.",
    valor_inss: "Valor INSS",
    valor_ir: "Vavlor IRRF",
    valor_pis: "Valor PIS",
    valor_cofins: "Valor COFINS",
    valor_csll: "Valor CSLL",
    local_prest_serv: "Local Prest. Serv.",
    cod_serv: "Cód. Serviço",
    impacto: "Impacto",

    eficiencia_tot: "Eficiência Total",
    eficiencia_min: "Eficiência Mínima",
    campos_tot: "Campos Total",
    campos_min: "Campos Mínimos",
    extracao_tot: "Campos Extraídos Total",
    extracao_min: "Campos Extraídos Mínimos",
    limite_efi_alta: "Limite Eficiência Alta",
    limite_efi_media: "Limite Eficiência Média",
    limite_efi_baixa: "Limite Eficiência Baixa",
    updated_at: "Data Últ. Alteração",
    created_at: "Data Criação",

    uuid_documento: "Documento",
    uuid_processo: "Processo",
    cliente_nome: "Cliente",
    prestador_nome: "Prestador",
    nome_arquivo: "Arquivo",
    url_arquivo: "Url",
    mensagem: "Mensagem",
    criticidade: "Criticidade",    
    statusNome: "Status",    
    data_fechamento: "Data Conclusão"
}
