import { BrowserModule } from "@angular/platform-browser";
import { NgModule, LOCALE_ID, APP_INITIALIZER } from "@angular/core";
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClientXsrfModule } from "@angular/common/http";
import { CommonModule, registerLocaleData } from "@angular/common";
import localePt from "@angular/common/locales/pt";
import { WINDOW_PROVIDERS } from "./window.providers";

import { AppRoutingModule } from "./app-routing.module";

import { AppComponent } from "./app.component";
import { ToastrModule } from "ngx-toastr";
import { TokenInterceptService } from "./services/token-intercept.service";
import { HeaderModule } from "./shared/header/header.module";
import { LoginModule } from "./pages/login/login.module";
import { MsalApplicationModule } from "./msal-application.module";
import { CookieService } from "ngx-cookie-service";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { FlexLayoutModule } from "@angular/flex-layout";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { ToasterModule } from "angular2-toaster";
import { SecureLocalStorage } from "@services/storage/secure-local-storage";
import { StorageService } from "@services/storage/storage.service";
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from "@angular/material/core";
import {
  MatMomentDateModule,
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  MAT_MOMENT_DATE_FORMATS,
  MomentDateAdapter,
} from "@angular/material-moment-adapter";
import { MatDatepickerModule } from '@angular/material/datepicker';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { BaseUrlInterceptorService } from "@services/baseurl.interceptor";
import { environment } from "src/environments/environment";
import { ReactiveFormsModule } from '@angular/forms';
import { FormlyModule } from '@ngx-formly/core';
import { FormlyMaterialModule } from '@ngx-formly/material';
import { ChartsModule } from 'ng2-charts';
import { AppInitService } from '@services/app-init.service';

registerLocaleData(localePt);
let paginatorOptions = new MatPaginatorIntl();
paginatorOptions.itemsPerPageLabel = "Itens por página";
paginatorOptions.getRangeLabel = (page: number, pageSize: number, length: number): string => {
  if (length === 0 || pageSize === 0) {
    return `0 / ${length}`;
  }
  length = Math.max(length, 0);
  const startIndex = page * pageSize;
  const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;
  return `${startIndex + 1} - ${endIndex} de ${length}`;
}

export function initializeApp(appInitService: AppInitService) {
  return (): Promise<any> => {
    return appInitService.init();
  }
}
@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    CommonModule,
    FlexLayoutModule,
    BrowserAnimationsModule,
    HttpClientModule,
    HttpClientXsrfModule,
    ToastrModule.forRoot({
      positionClass: "toast-top-center",
      enableHtml: true,
    }),
    ToasterModule.forRoot(),
    MsalApplicationModule.forRoot(),
    MatProgressBarModule,
    MatDatepickerModule,
    MatMomentDateModule,

    // Módulos do InvoiceCon
    HeaderModule,
    LoginModule,
    ReactiveFormsModule,
    FormlyModule.forRoot({ extras: { lazyRender: true } }),
    FormlyMaterialModule,
    ChartsModule
  ],
  providers: [
    CookieService,
    StorageService,
    SecureLocalStorage,
    WINDOW_PROVIDERS,
    {
      provide: HTTP_INTERCEPTORS,
      multi: true,
      useFactory: () => {
        return new BaseUrlInterceptorService(environment.api_endpoint);
      },
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptService,
      multi: true,
    },
    { provide: LOCALE_ID, useValue: "pt" },
    { provide: MAT_DATE_LOCALE, useValue: "pt-br" },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { displayDefaultIndicatorType: false, showError: true },
    },
    {
      provide: MatPaginatorIntl,
      useValue: paginatorOptions
    },
    AppInitService,
    { provide: APP_INITIALIZER, useFactory: initializeApp, deps: [AppInitService], multi: true },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor() { }
}
