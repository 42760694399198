import { Component } from '@angular/core';
import { EnvService } from '@services/env.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {
  logoUrl: string = EnvService.assetsUrl('assets/img/invoice-dark-theme.png')

  constructor() {
    let temaAplicacao = localStorage.getItem('temaAplicacao');
    if (temaAplicacao && temaAplicacao.length > 1) {
      this.logoUrl = EnvService.assetsUrl(`assets/img/invoice-${temaAplicacao}-theme.png`);
    }
  }
}
