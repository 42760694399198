
/**
 * Classe que define as permissões estáticas de acesso aos dados do sistema
 */
export const slug_decorators = {

    "CURADORIA":{
        "VISUALIZAR": "visualizar_curadoria",
        "MODELO": {
            "VISUALIZAR": "visualizar_curadoria",
            "ACESSAR": "visualizar_curadoria"
        },
        "PENDENCIA": {
            "VISUALIZAR": "visualizar_curadoria",
            "ACESSAR": "visualizar_curadoria",
            "CONSULTAR": "visualizar_curadoria"
        }
    },
    "CONFIABILIDADE":{
        "INTERPRETADOR": {
            "CONSULTAR": "visualizar_confiabilidade_inter"
        },
        "ERP": {
            "CONSULTAR": "visualizar_confiabilidade_erp"
        }
    },


};
