import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { LoginComponent } from "./login.component";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { RouterModule, Routes } from "@angular/router";
import { FlexLayoutModule } from '@angular/flex-layout';

const routes: Routes = [
  {
    path: "",
    component: LoginComponent
  },

]

@NgModule({
  declarations: [LoginComponent],
  imports: [
    FlexLayoutModule,
    CommonModule,
    MatProgressSpinnerModule,
    RouterModule.forChild(routes),
  ],
  exports: [LoginComponent, RouterModule],
})
export class LoginModule {}
