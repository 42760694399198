import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from "@angular/common/http";
import { Observable } from "rxjs";
import { EnvService } from "./env.service";

export class BaseUrlInterceptorService implements HttpInterceptor {
  constructor(private baseUrl: string) { }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (req.url.indexOf("assets") >= 0) {
      return next.handle(req);
    }

    const apiReq = req.clone({
      url: `${this.baseUrl}/${req.url}`,
      headers: req.headers
        .set("ambiente", EnvService.ambienteHeader)
        .set("cliente", EnvService.cliente),
    });
    return next.handle(apiReq);
  }
}
