import { Injectable, EventEmitter, Output, Directive } from '@angular/core';
import { ErroItem } from '../models/erro';

@Directive()
@Injectable({
    providedIn: 'root'
})
export class ErroService {
    @Output() erroNotify: EventEmitter<ErroItem> = new EventEmitter<ErroItem>();
    private _err: ErroItem = new ErroItem();

    eventoErro(
        cod?: string,
        mensagem?: string,
        tipo?: string,
        linkNome?: string,
        linkRoute?: string,
        linkParams?: Object,
        solucao?: string,
        onClick?: Function,
        titulo?: string,
        subtitulo?: string,
        detalhe?: string) {
        const e: ErroItem = {
            titulo: titulo || 'ERRO',
            subtitulo: subtitulo || 'Código do erro',
            cod: cod || 'ERRGERAL',
            mensagem: mensagem || 'Ocorreu um erro geral no InvoiceCon',
            tipo: tipo || 'geral',
            linkNome: linkNome || 'Ir para Home',
            linkRoute: linkRoute || 'home',
            linkParams : linkParams || null,
            solucao: solucao || 'Entre em contato com o administrador informando o erro',
            detalhe: detalhe,
            onClick: onClick
        }
        this.setErroItem(e);
        //this.erroNotify.emit(e);
    }

    setErroItem(e: ErroItem) {
        this._err = e;
    }

    getErroItem() {
        return this._err;
    }
}
