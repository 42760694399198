
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { IActionsServiceFacade } from '../facade/actions-service.interface';
import { EnvService } from '../env.service';
import { constants_decorators } from 'src/app/decorators/constants.decorators';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MessageService } from '../messages/message.service';
import { SecureLocalStorage } from '@services/storage/secure-local-storage';

@Injectable({
  providedIn: 'root'
})
export class BasisActionsService implements IActionsServiceFacade{

  protected _CONSTANTS = constants_decorators;
  protected _msgSrv: MessageService;

  constructor(
    protected _messageSrv: MessageService,
    protected _env: EnvService,
    protected secureLocalStorage: SecureLocalStorage,
    protected _http: HttpClient
  ) {

    this._msgSrv = _messageSrv;

   }

   private _listners = new Subject<any>();

  /******************************************************************************
   * MÉTODOS PARA GERAÇÃO DA URL BASE DO SERVIÇO QUE EXECUTARÁ A AÇÃO NO SERVIDOR
   ******************************************************************************/

  getUrlApiV2(): string{
    return this._CONSTANTS.ROTAS.API.V2;
  }

  getUrlEntidade(): string{
    return this._CONSTANTS.ROTAS.ENTIDADE.SISTEMA;
  }

  getJsonHeaders(){

    const headers = {
      'Content-Type': 'application/json',
    }

    const requestOptions = {
      headers: new HttpHeaders(headers)
    };

    return requestOptions;
  }
   /******************************************************************************
   * MÉTODOS COM IMPLEMENTAÇÃO GENÉRICA PARA REALIZAR AS AÇÕES EXECUTADAS NA TELA
   ******************************************************************************/

  async create(dados:any) {
    let urlBase = "";
    try{
      urlBase = this.getUrlApiV2() +
                this.getUrlEntidade() +
                this._CONSTANTS.ROTAS.ACAO.SALVAR;
     return await  this._http.post(urlBase, dados).toPromise();
    }catch(err){
      let msg = typeof err.error == "string" ? err.error : err.message;
      this._msgSrv.logErrorMessage({"msg":msg,"tit":"Alerta operação"});
      throw err;
    }
}

  async retrieve() {

    let urlBase = "";
    try{
      urlBase = this.getUrlApiV2() +
                this.getUrlEntidade() +
                this._CONSTANTS.ROTAS.ACAO.RECUPERAR;
      let lstDados = await this._http.get(urlBase).toPromise();
      if (lstDados) {
        this.setListaDados(lstDados);
      }
      return lstDados;
    }catch(err){
      let msg = typeof err.error == "string" ? err.error : err.message;
      // this._utils.logErrorMessage({"msg":"Erro na recuperação de dados","tit":"Alerta operação"});
      throw new Error(msg);
    }

  }

  async update(uuid: string, dados: any) {
    const urlBase = this.getUrlApiV2() +
                    this.getUrlEntidade() +
                    this._CONSTANTS.ROTAS.ACAO.ATUALIZAR
                   +"/"+ uuid
                    ;

    return await this._http.post(`${urlBase}`, JSON.stringify(dados)).toPromise();
  }

  async delete(uuid: string) {
    const urlBase = this.getUrlApiV2() +
                    this.getUrlEntidade() +
                    this._CONSTANTS.ROTAS.ACAO.EXCLUIR +
                   "/"+ uuid
                    ;

    return await this._http.delete(`${urlBase}`).toPromise();
  }

  async edit(uuid: string) {
    const urlBase = this.getUrlApiV2() +
                    this.getUrlEntidade() +
                    this._CONSTANTS.ROTAS.ACAO.EDITAR +
                   "/"+ uuid
                    ;

    return await this._http.get(`${urlBase}`).toPromise();
  }

  setListaDados(lstDados) {
  }


}
