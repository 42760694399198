import { Observable, of } from 'rxjs';
import { Injectable } from "@angular/core";
import { EnvService } from "./env.service";
import { HttpClient } from "@angular/common/http";
import { SecureLocalStorage } from "./storage/secure-local-storage";
import { map, tap } from 'rxjs/operators';

export interface Modulo {
  nome: string;
}

@Injectable({
  providedIn: "root",
})
export class ClienteService {
  private modulos: Array<Modulo> = [];
  public possuiNFSe: boolean = false;
  public possuiNFe: boolean = false;
  public possuiCTe: boolean = false;
  public possuiMDFe: boolean = false;

  private _cacheParticipantes: any;

  constructor(
    private _http: HttpClient,
    private secureLocalStorage: SecureLocalStorage,
    private _env: EnvService
  ) {
    this.setModulos();
  }

  public fetchModulos(uuid_cliente): Observable<Array<Modulo>> {
    return this._http.get<Array<any>>(`api/v2/cliente/${uuid_cliente}/modulos`).pipe(
      map((modulos) => {
        return modulos.map(modulo => {
          return { "nome": modulo.modulo }
        })
      }),
      tap((modulos) => {
        this.modulos = modulos;
        this.setModulos();
      })
    );
  }

  private setModulos() {
    if (!this.modulos) {
      return;
    }

    this.modulos.forEach((modulo) => {
      switch (modulo.nome) {
        case "NFSe":
          this.possuiNFSe = true;
          break;
        case "NFe":
          this.possuiNFe = true;
          break;
        case "CTe":
          this.possuiCTe = true;
          break;
        case "MDFe":
          this.possuiMDFe = true;
          break;
      }
    });
  }

  getModulos(): Array<Modulo> {
    return this.modulos;
  }

  possuiModulo(modulo: string): boolean {
    let mod = this.modulos.find((mod) => {
      return mod.nome == modulo;
    });

    return mod !== undefined;
  }

  getParticipantes(): Observable<any> {
    if (this._cacheParticipantes) {
      return of(this._cacheParticipantes);
    }

    return this._http.get(`api/cliente/participantes`)
      .pipe(tap(participantes => {
        this._cacheParticipantes = participantes;
      }));
  }
}
