import { Injectable } from "@angular/core";
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from "@angular/router";
import { permissoes_decorators } from "@decorators/permisssoes.decorators";
import { slug_decorators } from "@decorators/slug.decorators";
import { ClienteService } from "@services/cliente.service";
import { ConfigService } from '@services/config.service';
import { AuthService } from "../services/auth.service";

@Injectable({
  providedIn: "root",
})
export class PermissionGuardService implements CanActivate {
  constructor(
    protected _authSrv: AuthService,
    public _clienteSrv: ClienteService,
    public _configSrv: ConfigService,

  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    let path = route.routeConfig.path;

    switch (path) {
      case "home":
        return this._canHome();

      case "manifesto":
        return this._canManifesto();

      case "sintese-processo":
        return this._canSinteseProcesso();

      case "logs":
        return this._canLogs();

      case "admin/grupos":
      case "admin/usuarios":
        return this._canUsuarios();

      case "relatorios/aprovacao":
        return this._canRelatorioAprovacao();

      case "relatorios/anexos":
        return this._canRelatorioAnexos();

      case "relatorios/auxiliar":
        return this._canAuxiliar();

      case "relatorios/notas-canceladas":
        return this._canNotasCanceladas();

      case "admin/configuracoes":
        return this._canConfiguracoes();

      case "admin/reclassificar-requisitante":
        return this._canReclassificarRequisitante();

      case "processo/portaria":
        return this._canPortaria();

      case "recebimento":
        return this._canDoca();

      case "recebimento/sintese-recebimento":
        return this._canDoca();

      case "admin/unidade-medida":
        return this._clienteSrv.possuiNFe;

      case "admin/idprocesso":
        return this._clienteSrv.possuiNFe;

      case "admin/nfe-cnpj":
        return this._canNfeCnpj();

      case "admin/cte-cnpj":
        return this._canCteCnpj();

      case "admin/fornecedores-confiaveis":
        return this.__canFornecedoresConfiaveis();

      case "admin/imposto":
        return this._canAdminImposto();

      case "curadoria/consultar":
        return this._canAcessCuradoriaModeloConsultar();

      case "curadoria/pendencia":
        return this._canAcessCuradoriaPendencia();

      case "recomendacao":
        return this._canAcessRecomendacao();

      default:
        return true;
    }
  }

  _canHome(): boolean {
    return this._authSrv.can("visualizar_home");
  }

  _canManifesto(): boolean {
    const manifestoEnabled =
      this._configSrv.getConfigBySlug("manifesto_destinatario")?.valor ===
      "sim";
    return this._authSrv.can("manifesto_destinatario_monitor") && manifestoEnabled;
  }

  _canUsuarios(): boolean {
    return this._authSrv.can("usuarios");
  }

  _canLogs(): boolean {
    return this._authSrv.can("visualizar_logs");
  }

  _canConfiguracoes(): boolean {
    return this._authSrv.can("configuracoes");
  }

  _canPortaria(): boolean {
    return (
      this._authSrv.can("visualizar_portaria") && this._clienteSrv.possuiNFe
    );
  }

  _canDoca(): boolean {
    return this._authSrv.can("visualizar_doca") && this._clienteSrv.possuiNFe;
  }

  _canAdminImposto(): boolean {
    return this._authSrv.can('admin_imposto');
  }

  _canShowCuradoria(): boolean {
    return this._authSrv.can(permissoes_decorators.CURADORIA.VISUALIZAR);
  }

  _canShowNotificacoes(): boolean {
    return this._canShowCuradoriaPendencia()
      // || this._canAcessPendenciaConfiabilidade()
      // || this._canConsultPendenciaExcelencia()
      ;
  }


  _canShowCuradoriaModeloConsultar(): boolean {
    return this._authSrv.can(permissoes_decorators.CURADORIA.MODELO.VISUALIZAR);
  }

  _canShowCuradoriaPendencia(): boolean {
    return this._authSrv.can(permissoes_decorators.CURADORIA.PENDENCIA.VISUALIZAR);
  }

  _canAcessCuradoriaModeloConsultar(): boolean {
    return this._authSrv.can(permissoes_decorators.CURADORIA.MODELO.ACESSAR);
  }

  _canAcessCuradoriaPendencia(): boolean {
    return this._authSrv.can(permissoes_decorators.CURADORIA.PENDENCIA.ACESSAR);
  }

  _canConsultCuradoriaPendencia(): boolean {
    return this._authSrv.can(permissoes_decorators.CURADORIA.PENDENCIA.CONSULTAR);
  }

  _canAcessPendenciaConfiabilidade(): boolean {
    return this._authSrv.can(slug_decorators.CONFIABILIDADE.ERP.CONSULTAR)
      || this._authSrv.can(slug_decorators.CONFIABILIDADE.INTERPRETADOR.CONSULTAR);
  }

  _canConsultPendenciaExcelencia(): boolean {
    return true;
  }

  _canAcessRecomendacao(): boolean {
    return this._canShowCuradoriaPendencia();
  }

  _canConsultRecomendacao(): boolean {
    return this._canConsultCuradoriaPendencia();
  }

  _canNfeCnpj(): boolean {
    return (
      this._authSrv.can("nfe_cnpj") && this._clienteSrv.possuiNFe
    );
  }

  _canCteCnpj(): boolean {
    return (
      this._authSrv.can("cte_cnpj") && this._clienteSrv.possuiCTe
    );
  }

  __canFornecedoresConfiaveis(): boolean {
    return this._authSrv.can("fornecedores_confiaveis");
  }

  /**
   * Verificar acesso dinâmico para determinada permissao
   * @param slug
   * @returns
   */
  _filterConsult(slug: string): boolean {

    const slugs: string[] = slug.split(",");
    let canConsult = false;
    for (let i = 0; i < slugs.length && !canConsult; i++) {
      canConsult = this._authSrv.can(slugs[i]);
    }
    return canConsult;
  }

  _canSinteseProcesso(): boolean {
    let permissoes = [
      "interpretacao",
      "determinacao",
      "comparacao",
      "aprovacao",
      "escrituracao",
      "visualizar_interpretacao",
      "visualizar_determinacao",
      "visualizar_vinculacao",
      "visualizar_comparacao",
      "visualizar_aprovacao",
      "visualizar_escrituracao",
    ];

    for (let permissao of permissoes) {
      if (this._authSrv.can(permissao)) {
        return true;
      }
    }

    return false;
  }

  _canRelatorioAprovacao(): boolean {
    return this._authSrv.can("visualizar_rel_aprov");
  }

  _canRelatorioAnexos(): boolean {
    return this._authSrv.can("visualizar_rel_anexo");
  }

  _canAuxiliar(): boolean {
    return this._authSrv.can("auxiliar");
  }

  _canNotasCanceladas(): boolean {
    return this._authSrv.can("notas_canceladas");
  }

  _canReclassificarRequisitante(): boolean {
    return this._authSrv.can("requisitante_reatribuicao");
  }
}
