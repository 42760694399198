import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

export interface ConfigOption {
  value: string;
  name: string;
}

export interface Config {
  slug: string;
  nome: string;
  desc: string;
  valor: string;
  options: Array<ConfigOption>;
  tipo: string;
  required: boolean;
  aba?: string;
  visivel?: boolean;
}

export class ConfigCnpj {
  uuid: string;
  cnpj: string;
  logo: string;
}

@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  private configsLastCheck: number = Date.now();
  private configs: Array<Config> = [];
  private urlBase: string;

  constructor(
    private http: HttpClient) {
    this.urlBase = `api/v2/config`;
  }

  /**
   * Recupera as configurações do banco de dados
   */
  public fetchConfigs(): Observable<Array<Config>> {
    return this.http.get<Array<Config>>(`${this.urlBase}/allClient`).pipe(
      tap((configs) => {
        this.configs = configs;
      })
    );
  }

  async saveConfigs(configs) {
    const url = `${this.urlBase}/saveConfigs`;
    const dados = {
      configs
    };
    this.configs = [];
    return await this.http.post(url, dados).toPromise();
  }

  /**
   * Recupera as configurações
   */
  public getConfigs() {
    return this.configs;
  }

  /**
   * Recupera uma configuração específica com base no slug
   * @param slug slug da configuração que se deseja buscar
   */
  public getConfigBySlug(slug: string) {
    // Refresh das configurações a cada 2 minutos
    if (Date.now() > this.configsLastCheck + 120000) {
      this.configsLastCheck = Date.now();
      this.fetchConfigs().subscribe();
    }

    const config = this.configs.find(conf => conf.slug === slug);
    return config;
  }

  /**
   * Busca as configurações de impostos do cliente
   * @param tipo_processo Tipo de processo
   */
  async getImpostosConfig(tipoProcesso: string = null): Promise<any> {
    let url = `${this.urlBase}/impostos`;
    if (tipoProcesso) {
      url = url + `/${tipoProcesso}`;
    }
    return await this.http.get(url).toPromise();
  }

  /**
   * Salva as configurações de impostos
   * @param impostos Impostos
   */
  async storeImpostosConfig(impostos: any[]): Promise<any> {
    const url = `${this.urlBase}/impostos`;
    return await this.http.post(url, impostos).toPromise();
  }

  /**
   * Busca os impostos gravados na etapa de comparação
   * @param uuid uuid do processo
   */
  async getImpostosIgnoradosGravados(uuid: string): Promise<any> {
    const url = `${this.urlBase}/impostosGravados/${uuid}`;
    return await this.http.get(url).toPromise();
  }

  /**
   * Busca o token ArchBee
   * @param tipo_processo Tipo de processo
   */
  async getTokenArchBee(): Promise<any> {
    const url = `${this.urlBase}/archbee_token`;
    return await this.http.get(url).toPromise();
  }

  /**
   * Busca as configurações por CNPJ
   */
  public getConfigCnpj(): Observable<Array<ConfigCnpj>> {
    const url = `${this.urlBase}/cnpj`;
    return this.http.get<Array<ConfigCnpj>>(url);
  }

  /**
   * Grava as configurações por CNPJ
   */
  public saveConfigCnpj(configCnpj: ConfigCnpj): Observable<void> {
    const url = `${this.urlBase}/cnpj`;
    return this.http.post<void>(url, configCnpj);
  }

  /**
   * Grava as configurações por CNPJ
   */
  public deleteConfigCnpj(uuid: string): Observable<void> {
    const url = `${this.urlBase}/cnpj/${uuid}`;
    return this.http.delete<void>(url);
  }

  /**
   * Etapa de vinculação está ativa?
   * @param tipoProcesso Tipo do processo
   * @returns Etapa de vinculação ativa
   */
  public isEtapaVinculacaoAtiva(tipoProcesso: string): boolean {
    const slug = `etapa_vinculacao_ativa_${tipoProcesso.toLowerCase()}`;
    const config = this.getConfigBySlug(slug);
    return config && config.valor === 'sim';
  }
}
