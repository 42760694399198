<div class="modal-container">
    <h3>Status do ERP</h3>
    <div fxLayout="column" fxLayoutGap="20px" fxLayoutAlign="flex-start stretch" class="modal-body">
        <div class="header-main">
            {{ textoHeader }}
        </div>
        <div class="erro-msg">
            <span
                [ngClass]="statusClass"
            >
                {{ status }}
            </span>
            <!-- <span
                class="test-msg"
                *ngIf="loader"
            >
                Testando conexão com o ERP
            </span> -->
        </div>
        <div class="msg-botao" *ngIf="!loader">
            Você também pode fazer a verificação de conexão imediatamente clicando no botão
        </div>
        <div *ngIf="!loader" class="button-container">
            <button
                mat-button
                type="button"
                class="btn btn-primary"
                matTooltip="Verificar conectividade com o ERP"
                (click)="verificarErp()"
            >
            TESTAR COMUNICAÇÃO COM ERP
            </button>
        </div>
        <div class="test-load" *ngIf="loader">
            <!-- <div class="test-msg">
                Testando conexão com o ERP
            </div> -->
            <div class="spinner" >
                <mat-spinner></mat-spinner>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <div
            [ngClass]="statusClass"
            *ngIf="!loader && testeRealizado">
            {{ testResult }}
        </div>
        <button
            mat-button
            type="button"
            class="btn btn-secondary auto-margin-left"
            (click)="dialogRef.close()"
        >   
            FECHAR
        </button>
    </div>
</div>