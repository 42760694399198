export const cte_decorators = {
    nome: "CTE",
    label_step_determinacao: "Determinação",
    label_step_comparacao: "Comparação",
    label_step_escrituracao: "Escrituracao",

    label_nome_documento: "Serviço",
    label_codigo_documento: "Código do serviço",
    label_descricao_documento: "Descrição do serviço",
    label_valor_documento: "Valor total do serviço (R$)",

    label_item_documento: "Itens do serviço",

    label_prestador: "Emissor",
    label_tomador: "Tomador",
    label_min_tomador: "tomador",
    label_min_prestador: "emissor",
    label_cnpj_prestador: "CNPJ do emissor",
    label_cnpj_tomador: "CNPJ do tomador",
    label_remetente: "Remetente",
    label_min_remetente: "remetente",
    label_cnpj_remetente: "CNPJ do remetente",


};
