export const curadoria_decorators = {

    nome: "CURADORIA",


    ready: "Pronto",
    succeeded: "Sucesso",
    creating: "Treinando",
    invalid: "Inválido"


};
