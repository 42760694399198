export const nfe_devolucao_decorators = {
    nome: "NFE_D",

    label_step_determinacao: "Determinação",
    label_step_comparacao: "Simulação",
    label_step_aprovacao: "Aprovação",
    label_step_escrituracao: "Escrituração",

    label_nome_documento: "Produto",
    label_codigo_documento: "Código do produto",
    label_descricao_documento: "Descrição do produto",
    label_valor_documento: "Valor total do produto (R$)",

    label_item_documento: "Itens do produto",


    label_prestador: "Emitente",
    label_tomador: "Destinatário",
    label_min_tomador: "destinatário",
    label_min_prestador: "emitente",
    label_cnpj_prestador: "CNPJ do emitente",
    label_cnpj_tomador: "CNPJ do destinatário"

};
