import { Router } from "@angular/router";
import { catchError } from "rxjs/operators";
import { Injectable } from "@angular/core";
import { Observable, throwError } from "rxjs";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";

@Injectable({ providedIn: "root" })
export abstract class BaseService {

  protected httpHeaders = new HttpHeaders({
    "Content-Type": "application/json",
  });

  resolveRequest = (request: Observable<any>) =>
    request.pipe(catchError(this.error))

  protected error(e): Observable<ErrorEvent> {
    return throwError(e);
  }
}
