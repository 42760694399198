import { EventEmitter, Injectable, Output } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MsalService } from "@azure/msal-angular";
import { Router } from '@angular/router';
import { EnvService } from './env.service';
import { ErroService } from './erro.service';
import { ConfigService } from './config.service';
import { ClienteService } from './cliente.service';
import { tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { VersaoService } from '@services/versao.service';

export interface Permissao {
  slug: string;
  name: string;
  description: string;
}

@Injectable({
  providedIn: 'root'
})

export class AuthService {
  @Output() permissionsNotify: EventEmitter<void> = new EventEmitter<void>();

  private _usuario: {
    uuid_cliente: string,
    uuid_usuario: string,
    email: string;
    nome: string;
  }

  private _permissoesLastCheck: number = Date.now();
  private _permissoes: Array<Permissao> = [];

  private _isLoggedIn: boolean = false;

  constructor(
    private _http: HttpClient,
    private _msadalServ: MsalService,
    private _router: Router,
    private _erroSrv: ErroService,
    private _confSrv: ConfigService,
    private _clienteSrv: ClienteService,
    private _versaoSrv: VersaoService,
  ) {
    this._usuario = { uuid_cliente: null, uuid_usuario: null, email: '', nome: null };
  }

  public get isLoggedIn(): boolean {
    return this._isLoggedIn;
  }

  public get usuario() {
    return this._usuario;
  }

  private async setADUser() {
    let account = this._msadalServ.getAccount();
    let clientId = EnvService.cliente;

    const name = account.name;
    const email = account.userName;

    //Verifica se já temos usuario configurado para o AD em questão
    let userAD = { adClientId: clientId, nome: name, email: email };
    try {
      userAD.nome = decodeURIComponent(escape(userAD.nome))
    } catch (err) {
      userAD.nome = userAD.nome
    }
    try {
      let promises = [];

      // Verifica se o usuário já existe
      const resp = await this.getUsuarioAtual(clientId);
      this._usuario.uuid_cliente = resp[0].uuid_cliente;
      this._usuario.uuid_usuario = resp[0].uuid;
      this._usuario.email = resp[0].email;
      this._usuario.nome = userAD.nome;

      // Realiza as buscas na API
      promises.push(this.fetchPermissions().toPromise());
      promises.push(this._clienteSrv.fetchModulos(this._usuario.uuid_cliente).toPromise());
      promises.push(this._confSrv.fetchConfigs().toPromise());
      promises.push(this._versaoSrv.buscarVersaoErpRequerida());

      // Recupera os dados
      const result = await Promise.all(promises);

      // Verifica versão do ERP
      const infoVersao = result[3];
      if (infoVersao.versaoCliente < infoVersao.versaoRequerida) {
        this._redirecionaErro('ERRVERS',
          `O InvoiceCon foi bloqueado preventivamente por incompatibilidade da versão do ERP com a aplicação.
           A versão do InvoiceCon instalada no ERP (${infoVersao.versaoCliente}) é inferior à versão requerida (${infoVersao.versaoRequerida}).`,
          'login', 'Verificar novamente',
          'Solicite o pacote de atualização (via chamado no AMS) e providencie a aplicação do pacote nos ambientes, para que o acesso a aplicação seja normalizado.');
        return;
      }

      this._isLoggedIn = true;
    } catch (err) {
      console.error(err);

      let strErr = err.error
      let strDetail = ''
      if (err.error.toString() == '[object Object]') {
        strErr = err.error.error
        strDetail = err.error.message
      }

      if (err.status == 404 && strErr === 'Usuário não localizado') {
        this._redirecionaErro('ERRUSR', `Usuário não cadastrado na aplicação.`,
           'login', 'Sair da aplicação',
           'Contate um administrador e solicite que seja realizado o cadastro no sistema. OBS: Logout automático em 10s');
      } else if (err.status == 500 && strErr.indexOf('ERP') != -1) {

        if (strDetail?.indexOf(`usuário encontrado com`) >= 0 || strDetail?.indexOf(`user with email`) >= 0) {
          this._redirecionaErro(`null`, `Seu usuário não foi encontrado no ambiente ERP.`,
            'login', 'Tentar login novamente', `null`, ``, `null`, strDetail);
        } else {
          this._redirecionaErro(`ERRLOGIN`, `Ocorreu um erro inesperado na verificação de versão do ERP. Erro: ${strErr}`,
            'login', 'Tentar login novamente', 'Confirme se a conexão com o ERP está OK e tente novamente.', ``, ``, strDetail);
        }

      } else if (err.message.indexOf('of undefined') == -1 && err.error !== 'manutencao') {
        this._redirecionaErro('ERRLOGIN', `Ocorreu um problema na busca dos dados iniciais. Mensagem: ${err.message}`,
           'login', 'Verificar novamente', 'Contate o administrador do sistema e informe o código de erro.');
      } else if(err.message.indexOf('of undefined') == -1 && err.error === 'manutencao') {
        this._redirecionaErro('AVMANUT', `O InvoiceCon encontra-se em manutenção!`,
          'manutencao', 'Tentar login novamente', 'Aguarde alguns instantes ou entre em contato com o administrador.',
          'AVISO', 'Código do aviso');
      }
    }
  }

  private _redirecionaErro(codigo, mensagem, tipo, linkNome, solucao, titulo?, subtitulo?, detalhe?) {
    // Neste momento o redirecionamento tradicional não irá funcionar
    this._erroSrv.eventoErro(codigo, mensagem, tipo, linkNome, '/', null, solucao,
      (url) => { window.location.href = url }, titulo, subtitulo, detalhe);
    this._router.navigate(['erro']);
  }

  public getUsuarioAtual(clientId) {
    const url = `api/v2/usuario/email/${clientId}`
    return this._http.get(url).toPromise();
  }

  public logout(): void {
    this._permissoes = [];
    this._permissoesLastCheck = Date.now();
    this._isLoggedIn = false;
  }

  public async login(url: string) {
    if (this._isLoggedIn) {
      return;
    }

    // É importante obter a url neste momento, porque o LoadDataGuard irá redirecionar
    //   a aplicação para a tela de login

    // Até este ponto não é possível fazer nenhum acesso a API
    await this.setADUser();

    // Se não logou, encerra o login porque o método anterior já
    //   redirecionou para a página de erro
    if (!this._isLoggedIn) {
      return;
    }

    // A partir de agora é seguro acessar a API

    // Escolhe a rota a seguir conforme a origem

    // Primeira opção: a rota da URL, quando o acesso for feito para qualquer
    //   caminho que não seja o /
    let route = url;
    if (!route || route === "/") {
      // Segunda opção: SessionStorage, quando o usuário logou no AD pela primeira vez
      //   ou quando a classe TokenInterceptService executou acquireTokenRedirect
      route = sessionStorage.getItem("urlRedirectLogin");
      if (!route || route === "/") {
        // Terceira opção: rota default, quando o usuário acessar a raiz da aplicação (/)
        route = this.getDefaultRoute();
      }
      else {
        sessionStorage.removeItem("urlRedirectLogin");
      }
    }

    if (route.indexOf('/') === 0) {
      // Caso a rota possua um / no primeiro caracter, devemos remover
      route = route.substr(1, route.length)
    }

    // Redireciona a página
    // Não é utilizado o router navigation por problemas de NavigationCancel
      EnvService.redirectHref(route)
    // window.location.href = `${window.location.origin}/#/${route}`
  }

  getDefaultRoute() {
    if (this.can("visualizar_home")) {
      return "home";
    }
    if (this.can("visualizar_doca")) {
      return "recebimento";
    }
    if (this.can("visualizar_curadoria")) {
      return "recomendacao";
    }

    return "default";
  }

  private fetchPermissions(): Observable<Array<Permissao>> {
    return this._http.get<Array<Permissao>>('api/v2/usuario/permissao').pipe(
      tap((permissoes) => {
        this._permissoes = permissoes;
        this.permissionsNotify.emit();
      })
    )
  }

  public can(permission): boolean {
    // Refresh das permissoes a cada 2 minutos
    if (Date.now() > this._permissoesLastCheck + 120000) {
      this._permissoesLastCheck = Date.now();
      this.fetchPermissions().subscribe();
    }

    let perm = this._permissoes.find(perm =>
      perm.slug === permission
    );

    return perm !== undefined;
  }

  canPermissions(permissions: Array<string>) {
    const data = {};
    // Verifica as permissões que o usuário possui
    this._permissoes.forEach(permissao => {
      if (permissions.includes(permissao.slug)) {
        data[permissao.slug] = true;
      }
    });
    // Retorna no objeto as permissões que ele não possui
    permissions.forEach(permission => {
      if (!data[permission]) {
        data[permission] = false;
      }
    });
    return data;
  }
}
