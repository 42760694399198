import { HttpClient, HttpParams } from "@angular/common/http";
import { map, tap } from "rxjs/operators";
import { BaseService } from "./base.service";

export abstract class CrudService extends BaseService {
  constructor(protected httpClient: HttpClient) {
    super();
  }

  /**
   * Método que deve retornar qual o endpoint da api
   */
  abstract get apiEndpoint(): string;

  /**
   * Método para listar os dados
   *
   * @param params
   * @returns
   */
  list(params?: HttpParams) {
    return this.resolveRequest(
      this.httpClient.get<any>(`api/v2/${this.apiEndpoint}`, {
        headers: this.httpHeaders,
        params: params,
      })
    ).pipe(
      map(this.formatList),
      tap(results => {
        results.sort(this.sortFn)
      })
    );
  }

  /**
   * Formata os dados da lista
   *
   * @param data
   * @returns
   */
  formatList(data) {
    return data;
  }

  /**
   * Ordenação customizada
   *
   * @param a
   * @param b
   */
  sortFn(a, b) {
    return 0;
  }

  /**
   * Método para recuperar uma entidade
   *
   * @param id
   * @returns
   */
  getById(id: any) {
    return this.resolveRequest(
      this.httpClient.get<any>(`api/v2/${this.apiEndpoint}/` + id, {
        headers: this.httpHeaders,
      })
    );
  }

  /**
   * Método para recuperar as alterações de uma entidade
   *
   * @param id
   * @returns
   */
  revision(id: any) {
    return this.resolveRequest(
      this.httpClient.get<any>(
        `api/v2/${this.apiEndpoint}/${id}/revisions`,
        {
          headers: this.httpHeaders,
        }
      )
    );
  }

  /**
   * Método para criar uma entidade
   *
   * @param body
   * @returns
   */
  create(body) {
    return this.resolveRequest(
      this.httpClient.post<any>(
        `api/v2/${this.apiEndpoint}`,
        JSON.stringify(body),
        { headers: this.httpHeaders }
      )
    );
  }

  /**
   * Método para criar uma entidade
   *
   * @param body
   * @returns
   */
  batchCreate(body) {
    return this.resolveRequest(
      this.httpClient.post<any>(
        `api/v2/${this.apiEndpoint}/batch`,
        JSON.stringify({
          dados: body
        }),
        { headers: this.httpHeaders }
      )
    );
  }

  /**
   * Método para atualizar uma entidade
   *
   * @param body
   * @param id
   * @returns
   */
  update(body, id) {
    return this.resolveRequest(
      this.httpClient.patch<any>(
        `api/v2/${this.apiEndpoint}/` + id,
        JSON.stringify(body),
        { headers: this.httpHeaders }
      )
    );
  }

  /**
   * Método para remover uma entidade
   *
   * @param id
   * @returns
   */
  delete(id) {
    return this.resolveRequest(
      this.httpClient.delete<any>(
        `api/v2/${this.apiEndpoint}/` + id,
        {
          headers: this.httpHeaders,
        }
      )
    );
  }
}
