
export const actions_decorators = {

    "nome": "ACTIONS",

    "incluir": {

        "acao": "incluir",
        "mensagem": 'Adicionar novo registro',
        "titulo" : 'Novo registro adicionado com sucesso',
        "botao" : "ADICIONAR"

    },

    "clonar": {

        "acao": "clonar",
        "mensagem": 'Clonar o registro',
        "titulo" : 'Registro clonado com sucesso',
        "botao" : "CLONAR"

    },

    "editar": {

        "acao": "editar",
        "mensagem": 'Dados editados',
        "titulo" : 'Edição sucesso',
        "botao" : "EDITAR"

    },

    "salvar":{

        "acao": "excluir",
        "mensagem": 'Dados salvos',
        "titulo" : 'Gravação sucesso',
        "botao" : "SALVAR"

    },

    "excluir": {

        "acao": "excluir",
        "mensagem": 'Dados excluídos',
        "titulo" : 'Gravação sucesso',
        "botao" : "EXCLUIR"

    },

    "remover": {

        "acao": "remover",
        "mensagem": 'Dados removidos',
        "titulo" : 'Remoção sucesso',
        "botao" : "REMOVER"

    },

    "cancelar":{

        "acao": "cancelar",
        "mensagem": 'Dados restaurados para últimos valores salvos',
        "titulo" : 'Alterações canceladas',
        "botao" : "CANCELAR"
    },

    "consultar":{

        "acao": "consultar",
        "mensagem": 'Dados consultados com sucesso',
        "titulo" : 'Consultar dados',
        "botao" : "CONSULTAR"
    },

    "alterar":{

        "acao": "alterar",
        "mensagem": 'Dados alterados com sucesso',
        "titulo" : 'Alterar dados',
        "botao" : "ALTERAR"
    }

};
