import { Injectable } from "@angular/core";
import { EnvService } from "@services/env.service";
import { StorageService } from "./storage.service";

@Injectable({
  providedIn: "root",
})
export class SecureLocalStorage {
  constructor(private storageService: StorageService) {}
  // Set the data to local storage
  setItem(key: string, value: any) {
    this.storageService.secureStorage.setItem(`${EnvService.ambienteHeader}_${key}`, value);
  }

  // Get the value from local storage
  getItem(key: string) {
    return this.storageService.secureStorage.getItem(`${EnvService.ambienteHeader}_${key}`);
  }

  // Remove the value from local storage
  removeItem(key: string) {
    return this.storageService.secureStorage.removeItem(key);
  }

  // Clear the local storage
  clear() {
    return this.storageService.secureStorage.clear();
  }
}
